import Rebase from "re-base";
import firebase from "firebase";


//firebase.auth().currentUser.uid,
export async function getFile(filename, callback = null)
{
  const URL = `users_files/${filename}`
  var storage = firebase.storage();
  var storageRef = storage.ref(URL);
   

  storageRef.getDownloadURL().then((url)=>{   
    if(callback)
      callback(url)
    /*// `url` is the download URL for 'images/stars.jpg'
      
    // This can be downloaded directly:
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function(event) {
    var blob = xhr.response;
    };
    xhr.open('GET', url);
    xhr.send();

    // Or inserted into an <img> element:
    var img = document.getElementById('myimg');
    img.src = url;  

    */
  }).catch((error) => {
    callback(null);
  });
}



