import React, { Component } from 'react';
import styled from 'styled-components';
import Loading from "Components/Molecules/Loading";
import FileModal from "Components/Molecules/FileModal";
import firebase from "firebase"
import Rebase from "re-base"
import ItemSolicitud from "Components/Molecules/ItemSolicitud"
import Close from "react-md-icon/dist/RoundClose";
import Bitacora from "./Bitacora"
import axios from 'axios';

const _ = require('lodash');

class UserModal extends Component 
{
  constructor(props) 
  {
    super(props);
    this.state={
      solicitud: {},
      loading: true,
      usuario: null,
      currentFile: null,
      bitacora: false,
      
    }
    this.base = Rebase.createClass(firebase.database());
  }

  async componentDidMount(){    
    this.base.fetch('profile/'+this.props.usuario,{})
      .then((user)=>{

        this.base.fetch('solicitudes', {
          context: this,
          asArray: true,
          queries: {
            orderByChild: 'usuario',
            equalTo: this.props.usuario,
          },  
                
        }).then((data)=>{this.setState({solicitudes: data, usuario: user, loading: false})})

      })
  }

  async deleteUser(){
    this.setState({loading: true})
    await axios.post("https://boiling-earth-82137.herokuapp.com/deleteUser?token=adelantat-8b5f6",{
      id: this.props.usuario,
    }).then((res)=>{
      if(res.data.error)
      {
        this.setState({loading: false})
        alert("No se pudo borrar al usuario.")
      }
      else {
        firebase.database().ref("profile/" + this.props.usuario).remove().then(()=>{
          this.setState({loading: false})
        })
      }
    }).catch((err)=>{ 
      alert("Lo sentimos, no se pudo eliminar este usuario.")
    })
    this.props.continue()
  }

  render()
  {
    const close = this.props.close
    if(this.state.loading){
      return(
        <Loading  />
      )
    }
   
    return(
      <FullScreen>
        <PseudofullScreen onClick={close}/>
        <Card>
          <NavBar>
            <Content>
              <AlignStart>
                <Item2>
                  <Title2>
                    Usuario 
                  </Title2>
                </Item2>
    
              </AlignStart>
    
              <AlignEnd>
      
              </AlignEnd>

            </Content>
            <Close
              onClick={close} 
              style= {{ 
                position: "absolute",
                "top": "1rem",
                "right": "1rem",
                "fontSize":"22px",
                "cursor":"pointer",
                zIndex: 9999

              }}

            />
          </NavBar>
          <Container>
            <Title2>
              <b> Nombre:</b> {this.state.usuario.name}  {this.state.usuario.apellido}
            </Title2>

            <Title2>
              <b> Mail:</b> {this.state.usuario.mail}
            </Title2>

            <Title2>
              <b> Telefono: </b> {this.state.usuario.telefono}
            </Title2>

            {this.state.usuario.convenio && (
              <Title2>
                <b> Convenio:</b>  {this.state.usuario.convenio}
              </Title2>
            )}
            {this.state.solicitudes.length === 0 && 
            <React.Fragment>
              <hr />
              <Button 
                onClick={this.deleteUser.bind(this)}
                style={{marginTop:"3rem"}}
                red>Eliminar usuario</Button>
            </React.Fragment>

            }

            <Button 
              onClick={()=>{this.setState({bitacora: true})}}
            >
              Ver Bitácora
            </Button>
            <Title2>
              {this.state.solicitudes.length !== 0 && 
              <React.Fragment> 
                <hr />
                <b> Solicitudes:</b>
                {this.state.solicitudes.length}
              </React.Fragment>
              }
            </Title2>
            {_.map(this.state.solicitudes,(item, key) => (
              <ItemSolicitud 
                {...item} 
                key={item.id} />
            )
            )}
          </Container>
          {this.state.bitacora &&
          <Bitacora 
            close={()=>{this.setState({bitacora: false})}}
            solicitudes = {this.state.solicitudes}
            usuario={this.props.usuario}/>
          }
          {this.state.currentFile && 
          <FileModal  
            close = {()=>{this.setState({currentFile: null})}}
            {...this.state.currentFile}/>}
        </Card>
      </FullScreen>
    );
  }
}
export default UserModal



export const UserCirlce = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: ${props => props.src? "url(" +props.src+ ")": "url('http://placehold.it/50x50')"};
  display: inline-block;
  margin: 0 0.1rem;
`;

export const IconCirlce = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  ${props=>props.theme.default.utils.centerContent}
  overflow: none; 
  font-size: 24px;
  background: #DFDFDF;
`;

export const PrivacyTab = styled.button`
 position: relative;
 border: none;
  border-radius: 22px;
  height: 40px;
  font-weight: 400;
  font-size:13px;
  color:#444444;
  background:white;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.theme.default.utils.rowContent}

${IconCirlce}{
  
  height: 30px;
  width: 30px;
}
`;

export const PrivacyTabContainer = styled.div`
  height: 75%;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.theme.default.utils.rowContent}
  width:100%;
`;

export const PrivacyText = styled.div`
    margin: 0 0.4rem;
`;

const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.4);
  padding: 0rem;
  padding-top: 1%;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
`;

const Card = styled.div`
  margin: 1rem auto;
  border-radius: 7px;
  overflow: hidden;
  width: 80%;
  padding-bottom: 1rem;
  color: rgba(0,0,0,0.8);
  background: white;
  position: relative;
  text-align: left;
  box-shadow: 0 0.5px 10px rgba(0,0,0,0.3);
  
    @media (max-width: 700px) {
    width: 90%;
    
  }
 
`;


const NavBar = styled.div`
 position: relative;
width: 100%;
  top:0;
  left:0;
  width: 100%;
  height: 65px;

  background: white;

  ${props=>props.theme.default.utils.centerContent}
`;



const Container = styled.div`
  width:90%;
  margin: 2rem auto;
   padding-top: 65px;
   text-align: center;
`;



const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  margin-right: 1rem;
  border-radius: 7px;
  overflow: hidden;
  background: ${props => !props.red?props.theme.colors.green: "none"};
  color: ${props => !props.red?"white": "red"};
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  border:${props => props.red?"solid 1px red": "0"};
     @media (max-width: 700px) {
          margin: 1rem auto;
          display: block;
         } 
`;


const Content = styled.div`
 position: relative;
 width: 100%;
 background: #F8F9FA;
    ${props=>props.theme.default.utils.rowContent}    
    padding: 1rem;
   @media (max-width: 700px) {
          display: inline-block;
         }
`;



const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;

    min-width: 0;
    height: 100%;

    flex: 1;
    z-index: 1;
    
     @media (max-width: 700px) {
          display: block;
         }

`;

const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
  
`;

const AlignEnd =  styled(_Section)`
    justify-content: flex-end;
    order: 1;
  
`;

const Title2 = styled.div`
  margin: 1rem 0;
  font-weight: 400;
  text-align: left;
  font-size: 22px;
  color: #202124;
`;

const Item2 = styled.div`
  position: relative; 
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
  text-align: left;

  min-width: 45px; 
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               display: inline;
           }      
    `}

     ${props=>props.hide && `
         @media (max-width: 700px) {
          display: none;
         }
      `}
`;


