import React, {Component, Fragment} from 'react';
import { Container, PseudoContainer, Content, Description, ImageContainer, Image, Information, Name, Email, AccountIcon, ExploreIcon, SettingsIcon, ExitIcon} from './elements.js';
import BaselineAccountCircle from "react-md-icon/dist/BaselineAccountCircle";
import ListItem from './ListOptions/ListItem/';
import LineBreak from './ListOptions/LineBreak/';

class ProfileOptions extends Component {
  render() {
    return(
      <Fragment>
        <Container>
          <PseudoContainer/>
        </Container>
        <Content>
          <br/>
				
          <ListItem
            link="/configuracion" 
            icon={<SettingsIcon/>}
            description="Configuración"/>
          <br/>

          <LineBreak/>
          <ListItem
            link="/logout"
            icon={<ExitIcon/>} 
            description="Cerrar sesion"/>
        </Content>
      </Fragment>
    )
  }
}

export default ProfileOptions;
