import React, {Component } from 'react';
import styled , {keyframes, css } from 'styled-components';
import ReactDOM from 'react-dom';

import {Redirect, Link, BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import { connect, Provider } from "react-redux";

import { 
  combineReducers, 
  applyMiddleware, 
  createStore, 
  bindActionCreators 
} from "redux";

const _ = require('lodash');

const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.8);
  padding: 0rem;
  padding-top: 5%;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
`;

const Sidebar = styled.div`
  width: 75%;
  position: fixed;
  padding-top: 4rem;
  top: 0;
  left:0;
  bottom:0;
  font-family: 'Rubik', sans-serif;
  background: white;
  text-aling: left;
  box-shadow:0 0.03px 5px rbga(0,0,0,0.5);
  z-index: 99999;
    
`;


const Icon = styled.i`
        vertical-align: bottom;
        margin-right: 1rem;
      `;

const Button  = styled.a`
        position: relative;
        padding:  0.3rem 0.8rem;
        margin: 0.5rem;
        border-radius: 3px;
        font-size: 16px;
        overflow: hidden;
        background: #64b5f6;
        color: white;
        cursor: pointer;
        text-decoration: none;
      `;

const NotebookHead = styled.div`
        width: 20%;
        position: fixed;
        top: ${64}px;
        left:0;
        bottom:0;
        font-family: 'Rubik', sans-serif;
        background: #fafafa;
        text-aling: left;
        box-shadow: 0 0.5px 5px rgba(0,0,0,0.3);
        z-index: 0;
        padding-top: 2rem; 
          @media (max-width: 700px)
          {
            display: none;
          }
      `;

const ContexCard = styled.div`
          width: 300px;
          text-align: left;
          background: white;
          padding: 1rem;
          border-radius: 2px;
          color: grey;
      `;

const ShowUp = styled.div`
          position: relative;
          background: red;
      `;
const Ul = styled.ul`
          list-style:  none;
          font-size: 16px;
          width: 100%;
          overflow: hidden;
          font-weight: 400;
      `;

const Li = styled.li`
        width: 100%;
        padding: 0rem 0;
      `;

const LinkText = styled.div`
        text-decoration: none;
        color: #ffffff;
      `;

const Head = styled.p`
        margin-top: 0.1rem;
        text-decoration: none;
        color:${props=>props.active?props.theme.colors.green:"#7D7582"} ;
        font-size: 18px;
        padding: 0 1rem;
        cursor: pointer;
        text-aling: left;
        position: relative;
        a{
            text-decoration: none;
            color:${props=>props.active?props.theme.colors.green:"#7D7582"} ;
        }
      `;

const Selection = styled.label`
        position: absolute;
        top: -0.8rem;
        left: 0rem;
        height: 42px;
        width: 100%;
        cursor: pointer;
        background: #3700B3;
        opacity:0.06;
        border-radius: 0 20px 20px 0 ;
      `;


const Footer = styled.label`
        position: absolute;
        bottom:1rem;
        font-size: 14px;
        color: grey;
        left:1rem;
        width: 100%;
      `;




class Notebook extends Component {

  constructor(){
    super();
    this.state = {
      solicitudes: false,
      cobros: false,
      dispersiones: false,
    }
  }

  render()
  {
        
       
    const close = this.props.close//?this.props.close:this.props.hideOnClick;
       
    const tab = this.props.location.pathname.split("/").length



    return (
      <FullScreen>
        <PseudofullScreen onClick={close}/>
        <Sidebar>

          <Head
            onClick={close} 
            active = {this.props.location.pathname === ("/")}>
            <Link to="/">
              <Icon 
                className="material-icons">home</Icon>Inicio
            </Link>
          </Head>

          <Head
            onClick={close} 
            active = {this.props.location.pathname.includes("/usuarios")}>
            <Link to="/usuarios">
              <Icon 
                className="material-icons">people</Icon>Usuarios
            </Link> 
          </Head>

          <Head
            onClick={()=>{this.setState({solicitudes: !this.state.solicitudes})}}

          >
            
            <Icon 
              className="material-icons">playlist_add</Icon>Solicitudes
            <Icon 
              style={{fontSize:"18px"}}
              className="material-icons">{this.state.solicitudes?"arrow_drop_up":"arrow_drop_down"}</Icon>
          
          </Head>

          {this.state.solicitudes && <Ul>
            <Li>
              <Head
                onClick={close} 
                active = {this.props.location.pathname.includes("/solicitudes") && tab === 2}>
                <Link to="/solicitudes">
                  Recientes
                </Link> 
              </Head>
            </Li>
            <Li>
              <Head
                onClick={close} 
                active = {this.props.location.pathname.includes("/solicitudes/aprobadas")}>
                <Link to="/solicitudes/aprobadas">
                  Autorizadas
                </Link> 
              </Head>
            </Li>
            <Li>
              <Head
                onClick={close} 
                active = {this.props.location.pathname.includes("/solicitudes/dispersion")}>
                <Link to="/solicitudes/dispersion">
                  En dispersión
                </Link> 
              </Head>
            </Li>

            <Li>
              <Head
                onClick={close} 
                active = {this.props.location.pathname.includes("/solicitudes/cobro")}>
                <Link to="/solicitudes/cobro">
                  En Cobros
                </Link> 
              </Head>
            </Li>
            <Li>
              <Li>
                <Head
                  onClick={close} 
                  active = {this.props.location.pathname.includes("/solicitudes/canceladas")}>
                  <Link to="/solicitudes/canceladas">
                    Rechazados
                  </Link> 
                </Head>
              </Li>
              <Head
                onClick={close} 
                active = {this.props.location.pathname.includes("/solicitudes/finalizadas")}>
                <Link to="/solicitudes/finalizadas">
                  Finalizado
                </Link> 
              </Head>
            </Li>
          </Ul>}

          <Head 
            onClick={()=>{this.setState({dispersiones: !this.state.dispersiones})}}

          >
            
            <Icon 
              className="material-icons">playlist_add_check</Icon>Dispersiónes 
            <Icon 
              style={{fontSize:"18px"}}
              className="material-icons">{this.state.dispersiones?"arrow_drop_up":"arrow_drop_down"}</Icon>
          
          </Head>

          {this.state.dispersiones && <Ul>
            <Li>
              <Head
                onClick={close} 
                active = {this.props.location.pathname.includes("/dispersion") && tab === 2}>
                <Link to="/dispersion">
                  Por dispersar
                </Link> 
              </Head>
            </Li>
            <Li>
              <Head
                onClick={close} 
                active = {this.props.location.pathname.includes("/dispersion/dispersados")}>
                <Link to="/dispersion/dispersados">
                  Dispersados
                </Link> 
              </Head>
            </Li>
            <Li>
              <Head
                onClick={close} 
                active = {this.props.location.pathname.includes("/dispersion/canceladas")}>
                <Link to="/dispersion/canceladas">
                  Cancelados
                </Link> 
              </Head>
            </Li>
                     
          </Ul>}

          <Head 
            onClick={()=>{this.setState({cobros: !this.state.cobros})}}


          >
            
            <Icon 
              className="material-icons">monetization_on</Icon>Cobros                            
            <Icon 
              style={{fontSize:"18px"}}
              className="material-icons">{this.state.cobros?"arrow_drop_up":"arrow_drop_down"}</Icon>
          
          </Head>

          {this.state.cobros &&  <Ul>
            <Li>
              <Head
                onClick={close} 
                active = {this.props.location.pathname.includes("/cobro") && tab === 2}>
                <Link to="/cobro">
                  Por cobrar
                </Link> 
              </Head>
            </Li>
            <Li>
              <Head
                onClick={close} 
                active = {this.props.location.pathname.includes("/cobro/cobrados")}>
                <Link to="/cobro/cobrados">
                  Cobrados
                </Link> 
              </Head>
            </Li>
                            
                              
          </Ul>}
        </Sidebar>
         
      
      </FullScreen>
    )}
}
        


export default withRouter(Notebook)