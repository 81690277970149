
import React, { Component } from 'react';
import styled ,{keyframes, css, ThemeProvider}from 'styled-components';
import ReactDOM from 'react-dom';
import { Redirect, Link,BrowserRouter, Route, Switch, withRouter} from 'react-router-dom';
import { connect, Provider } from "react-redux";
import { combineReducers, applyMiddleware, createStore, bindActionCreators  } from "redux";
import firebase from "firebase"
		 //Imports.
//{getImports(data.component.imports)}
   

		

const _ = require('lodash');

const Logout = withRouter((props)=> {


  //Code
  class Logout extends Component {
    constructor() {
      super()
      this.state = {
        redirect: false
      }
    }

    componentWillMount() {
      firebase.auth().signOut().then((user) => {
        this.setState({ redirect: true })
      })
    }

    render() {
      if (this.state.redirect === true) {
        return <Redirect to="/" />
      }

      return <h1>Cerrando sesion 👋🏽</h1>
    }
  }

  return <Logout />
                
}
)

        


export default Logout