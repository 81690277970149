import React  from 'react';
import styled from 'styled-components';
import {  Route, Switch } from 'react-router-dom';
import Table from "./Table"
import TableCobro from "./Table/indexCobro"


const Solicitudes = ()=> {
  return(
    <Body>

      <Switch>
        <Route
          exact
          path={"/solicitudes"} 
          render={()=>{
            return (
              <React.Fragment>
                <Title>Solicitudes recientes</Title>
                <Table
                  query={1} 
                  key="all"
                />
              </React.Fragment>
            )
          }}

        />
        <Route
          path={"/solicitudes/aprobadas"} 
          render={()=>{
            return (
              <React.Fragment>
                <Title>Solicitudes autorizadas</Title>
                <Table
                  query={2}
                  key="aprobadas"/>
              </React.Fragment>)
          }}

        />

        <Route
          path={"/solicitudes/dispersion"} 
          render={()=>{
            return (
              <React.Fragment>
                <Title>Solicitudes en proceso de dispersión</Title>
                <Table
                  query={3}
                  key="dispersion"/>
              </React.Fragment>
            )

          }}

        />

        <Route
          path={"/solicitudes/cobro"} 
          render={()=>{
            return (
              <React.Fragment>
                <Title>Solicitudes en proceso de cobro</Title>
                <TableCobro
                  query={4}
                  key="cobro"/>
              </React.Fragment>)
          }}
        />

        <Route
          path={"/solicitudes/canceladas"} 
          render={() => {
            return (
              <React.Fragment>
                <Title>Solicitudes canceladas</Title>
                <Table
                  query={0}
                  key="canceladas"/>
              </React.Fragment>)
          }}
        />
      
        <Route
          path={"/solicitudes/finalizadas"} 
          render={() => {
            return (
              <React.Fragment>
                <Title>Solicitudes finalizadas</Title>
                <Table
                  query={5}
                  key="finalizadas"/>
              </React.Fragment>
            )
          }}
        />
      </Switch>
    </Body>
  )
                
}

export default Solicitudes

const Body = styled.div`
  width: 90%;
  padding: 1rem;
  margin: 0 auto;
  overflow: none;
  @media print {
    width: 100%;
    padding:3px;
  }
`;
const Title = styled.div`
  padding-top: 1.2rem;
  color: rgba(0,0,0,0.5);
  font-size: 18px;
  font-weight: 500;
`;