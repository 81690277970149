import React from 'react';
import { Text, IconContainer } from './elements';
import { Link } from 'react-router-dom';

const ListItem = (props) => (
  <Text>
    <Link to={props.link}>
      {props.icon && <IconContainer>{props.icon}</IconContainer>}
      {props.description}
    </Link>
  </Text>

)

export default ListItem;
