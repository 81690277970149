import React from 'react';
import firebase from 'firebase';

export let TEMPORALCONTEXTFIREBASE = React.createContext();

export const config = {
  apiKey: "AIzaSyA8em4N663s_tTPGL1DRqWMO8l-18hFHqk",
  authDomain: "adelantat-8b5f6.firebaseapp.com",
  databaseURL: "https://adelantat-8b5f6.firebaseio.com",
  projectId: "adelantat-8b5f6",
  storageBucket: "adelantat-8b5f6.appspot.com",
  messagingSenderId: "521163458606"
};
firebase.initializeApp(config);
export default class FirebaseProvider extends React.Component
{
  constructor()
  {
    super()
    this.state = {
      user: null,
      loading: true,
    }
  }
    
  async  __firebaseLogin()
  {
    this.__firebaseAuthListener = firebase.auth().onAuthStateChanged(async (user) =>
    {
      if (user) 
      {
        await this.setState({user,loading: false})
      }      
      else 
      {
        await this.setState({user:null, loading: false})
      }
    })
  }

  componentWillUnmount()
  {
    this.__firebaseAuthListener();
  }
  componentDidMount()
  {
    this.__firebaseLogin()
  }
  
  render()
  {     
    return (
      <TEMPORALCONTEXTFIREBASE.Provider 
        value={{
          user: this.state.user,
          loading : this.state.loading
        }}>       
        {this.props.children}
      </TEMPORALCONTEXTFIREBASE.Provider>
    );
  }

}


export const withFirebase = (WrapperedComponent)=>{
  return (props)=>(
    <TEMPORALCONTEXTFIREBASE.Consumer>      
      {(rest)=>(
        <WrapperedComponent
          {...rest}
          {...props}/>
      )}
    </TEMPORALCONTEXTFIREBASE.Consumer>
  )
}