import React  from 'react';
import styled from 'styled-components';

import { Route, Switch } from 'react-router-dom';

import Table from "./Table"

const Dispersion = ()=> { 
  return(
    <Body>
      <Switch>
        <Route
          exact
          path={"/dispersion"} 
          render={()=>{
            return (<React.Fragment>
              <Title>Adelantos por dispersar</Title>
              <Table
                query={1} 
                key="all"
              />
            </React.Fragment>)
          }}
        />
        <Route
          exact
          path={"/dispersion/dispersados"} 
          render={()=>{
            return (<React.Fragment>
              <Title>Adelantos dispersados</Title>
              <Table
                query={2}
                key="dispersados"/>
            </React.Fragment>)
          }}
        />
        <Route
          exact
          path={"/dispersion/canceladas"} 
          render={()=>{
            return (<React.Fragment  >
              <Title>Dispersiones canceladas</Title>
              <Table
                query={0}
                key="canceladas"/>
            </React.Fragment>)
          }}
        />  
      </Switch>

    </Body>
  )
                
}

export default Dispersion

const Body = styled.div`
  width: 90%;
  padding: 1rem;
  margin: 0 auto;
  overflow: none;
  @media print {
  width: 100%;
  padding:3px;
  }
`;

const Title = styled.div`
  padding-top: 1.2rem;
  color: rgba(0,0,0,0.5);
  font-size: 18px;
  font-weight: 500;
`;