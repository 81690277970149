import styled from "styled-components";
import React, { Component } from "react";
import Popover from "react-popover";
import { Link } from 'react-router-dom';
import ProfileCard from "../ProfileCard"
const popoverProps = {
  preferPlace: "below",
  place: null
};

const Container = styled.div` 
  width: 200px;

  @media (max-width: 700px) {
          margin-top:100px;
         }


`;
//${props=>props.theme.elevation(1)}
export const Item = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 1rem;
  height: 40px;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-bottom: solid 0.5px rgba(0, 0, 0, 0.4);
  .material-icons{
    text-align: right;
  }
`;

const Name = styled.div`
  cursor: pointer;
  width: 100%;

  display: flex;
  align-items: center;
  box-sizing: border-box;

  font-size: 16px;
  font-weight: 400;
  text-align: left;
  display: inline-block; 
 
`;


const Title = styled.div`
text-align: left;
font-size: 18px;
color: #444444;
font-weight: 500;
`;

const Icon = styled.i`
  vertical-align: bottom;
`;

class Options extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  renderContent()
  {
    return (
      <Container >


        <ProfileCard user={this.props.user}/>


        {/* <Item>
                 <Link 
                   style={{color: "rgba(0,0,0,0.5)"}} 
                   to={`/u/${this.props.user.displayName}`}>
                   <Name>{this.props.user.displayName}</Name>
                 </Link>
               </Item>
               
               <Item>
             <Link style={{color: "rgba(0,0,0,0.5)"}} to="/logout">
             <Icon className="material-icons">exit_to_app</Icon> Sign out</Link>
             </Item>*/}
      </Container>
    )
  }

  render() {
    return (
      
      <Popover
        onOuterAction={() => this.setState({ open: false })}
        isOpen={this.state.open && !this.props.forceClose}
        {...popoverProps}
        body={[this.renderContent()]}>
        
        <div
          style={{"display":"inline-block", "width":"100%"}}
          onClick={(e) => {
            e.preventDefault();
            this.setState({ open: !this.state.open });
          }}>
          {this.props.children}
        </div>
      </Popover>
    
 
    );
  }
}

export default Options;


/*

  [{type:"link", url:"", title:"", icon:"", actions: null}]

  <Options options={{[]}}>
    <div here={{cantabria="This is the time and money it has to work..."}} />
  </Options>

*/