import Rebase from "re-base";
import firebase from "firebase";
import {updateSolicitud} from "../Solicitudes"
import {createCobro} from "../Cobros"

const base = Rebase.createClass(firebase.database());
const uuid = require('uuid');

export async function createDispersion(data){
  //Actualizamos toda la informacion del usuario.
  //[Solicitud]

  const {
    id  = "",
    banco =  "",
    clabe = "",
    convenio = "",
    cuenta = "",
    usuario = "",
    nombreUsuario = "",
    folio = ""
   
  } =  data

  firebase.database().ref(`dispersiones/${id}`).set(
    {
      id,
      banco,
      clabe,
      convenio,
      cuenta,
      folio,
      usuario,
      fecha: Date.now(),
      nombreUsuario,
      solicitud: data.id,
      monto: data.montoAprobado,
      status: 1,
      action: "NEW_DISPERSION"
    }).then((res)=>{

  }).catch((err)=>{
    alert("Hubo un error con la conexion, intenta de nuevo.")
    //Show Error mesage
  })
}


export async function updateDispersion(data){
  //Actualizamos toda la informacion del usuario.
  //[Solicitud]
  firebase.database().ref(`dispersiones/${data.id}`).update(
    {...data,
      fechaDeActualizacion: Date.now(),
    }).then((res)=>{

  }).catch((err)=>{
    alert("Hubo un error, intenta de nuevo.")
    //Show Error mesage
  })


  /*
    //Si al actualizar hay nueva informacion de conextion entonces actualizamos el conector.
    if(newConnect)
        updateConnectSolicitud(newConnect)

    */
}

 
export async function cancelarDispersion(data){
  //Actualizamos toda la informacion del usuario.
  //[Solicitud]
  alert("China")
  updateDispersion({id: data.id, status:0})
  updateSolicitud({id: data.id, status:0})

  /*
    //Si al actualizar hay nueva informacion de conextion entonces actualizamos el conector.
    if(newConnect)
        updateConnectSolicitud(newConnect)

    */
}


export async function aceptarDispersion(data){
  //Actualizamos toda la informacion del usuario.
  //[Solicitud]
  await updateDispersion({id: data.id, status:2, action: "NEW_STATUS"})
  await updateSolicitud({id: data.id, status:4,action: "NEW_STATUS"})
  await createCobro(data)

  /*
    //Si al actualizar hay nueva informacion de conextion entonces actualizamos el conector.
    if(newConnect)
        updateConnectSolicitud(newConnect)

    */
}