import React, { Component } from 'react';
import styled from 'styled-components';
import {  withRouter } from 'react-router-dom';
import  firebase  from "firebase";
import Rebase from 're-base';
import SolicitudModal from "Components/Molecules/SolicitudModal";
import RoundSearch from "react-md-icon/dist/RoundSearch";
import { formatMoney } from 'utils/formatMoney';
import { dateFormatter } from 'utils/dateFormatter';
import { filterByKey } from 'utils/filterByKey';
import placeHolderImage from "static/img/success.png"
	
const _ = require('lodash');

class TableSolicitudCobro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter:"",
      clickHeader: false,
      addMemberModal: false,
      addMember: {
        email: "",
        role: "",
      },
      updateRole: " ",
      loading: true,
      solicitudes: [],
      solicitud: null,
      filterUp: false
    }
		
    this.base = Rebase.createClass(firebase.database());
  }


  componentDidMount(){
    this.shutDownProfiles = this.base.syncState(`solicitudes`, {
      context: this,
      asArray:true,
      queries: {
        orderByChild: 'status',
        equalTo: this.props.query,
      },
      state: "solicitudes",
      then(){
        this.setState({loading: false})   
      }
    });
  }
      
  componetWillUnmount()
  {
    this.shutDownSolicitudes()
  }

  
  render() {

    const {
      filter = "", 
      solicitudes = [],
      filterUp
    } = this.state

    const normalizedFilter  = filter?.toLowerCase?.() || ''
    const solicitudesFiltradas = _.filter(filterByKey(solicitudes, "fechaAprobada", filterUp),
      (solicitud) => (typeof filter === 'string' ? 
        solicitud?.folio?.toLowerCase?.()?.includes(normalizedFilter)
        ||
        dateFormatter(solicitud.fecha).includes(normalizedFilter)
        ||
        solicitud?.nombreUsuario?.toLowerCase?.()?.includes(normalizedFilter)
        ||
        solicitud?.convenio?.toLowerCase?.()?.includes(normalizedFilter) : true
      ))
    return(
      <Container>
        <PseudoContainer onClick={() => this.setState({clickHeader: false})}/>
        <Content>
          <Header active={this.state.clickHeader}>
            <Form onClick={() => this.setState({clickHeader: true})}>
              <IconContainer for="search">
                <SearchIcon /> 
              </IconContainer>
              <Input 
                id="search" 
                type="text" 
                onChange={(e)=>{this.setState({filter:e.target.value})}}
                placeholder="Buscar solicitud por folio, usuario o convenio" 
                active={this.state.clickHeader}/>
							  
            </Form>
				
          </Header>

          <Information onClick={() => this.setState({clickHeader: false})}>
						
            <Item
              style={{width:"15%"}}
              onClick={()=>{this.setState({filterUp:!this.state.filterUp})}}
            >Fecha de cobro<i
                className="material-icons"
                style={{cursor:"pointer"}}
              >{this.state.filterUp?"arrow_drop_down":"arrow_drop_up"}</i></Item>
            <Item style={{width:"5%"}}>Folio</Item>
            <Item style={{width:"20%"}}>Nombre</Item>
            <Item style={{width:"10%"}}>Convenio</Item>
            <Item style={{width:"10%"}}>Monto</Item>
            <Item style={{width:"10%"}}> A cobrar</Item>
            <Item style={{width:"10%"}}>Banco</Item>
            <Item style={{width:"20%"}}>CLABE</Item>
          </Information>

          <MemberList onClick={() => this.setState({clickHeader: false})}>
            {this.state.loading && <Center> <b>Cargando...</b> </Center>}
            {!this.state.loading && this.state.solicitudes.length === 0 &&<Center> 
              <img
                src={placeHolderImage}
                alt="success"/>
              <h2>No hay datos</h2>
            </Center>}

            {_.map(solicitudesFiltradas,( solicitud, index) => (		
                  
              <Information 
                onClick={()=>{this.setState({solicitud:solicitud.id})}}
                style={{
						    background: "white",
						    fontSize: "22px",
						    height: "64px"
					    }}>

                <Item style={{width:"15%"}}>{ 
                  (()=>{
                    const d = new Date(solicitud.fecha)
                    return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`
                  })()
						 }</Item>
                <Item style={{width:"5%"}}>{solicitud.folio}</Item>
                <Item style={{width:"20%"}}>{solicitud.nombreUsuario}</Item>
                <Item style={{width:"10%"}}>{solicitud.convenio?solicitud.convenio:"Sin convenio"}</Item>
                <Item style={{width:"10%"}}>${formatMoney(solicitud.monto)}</Item>
                <Item style={{width:"10%"}}>${formatMoney(parseFloat(solicitud.montoAprobado) + (parseFloat(solicitud.montoAprobado) * 0.10) )}</Item>
                <Item style={{width:"10%"}}>{solicitud.banco}</Item>
                <Item style={{width:"20%"}}>{solicitud.clabe}</Item>
              </Information>))} 

          </MemberList>



          <Footer onClick={() => this.setState({clickHeader: false})}>
					
          </Footer>
        </Content>
        {this.state.solicitud && <SolicitudModal 
          close = {()=>{this.setState({solicitud: null})}}
          id={this.state.solicitud}/>
        }
      </Container>
    )
  }
}
export default withRouter(TableSolicitudCobro)



const Container = styled.div `
	width: 100%;
	height: auto;
	padding-top: 30px;
	display: flex;
	justify-content: center;
	position: relative;

`

const PseudoContainer = styled.div `
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: transparent;
	z-index: 9;
`;

const Content = styled.div `
	width: 100%;
	height: auto;
	border-radius: 7px;
	background: white;
	box-shadow: 0 0.5px 3px rgba(0, 0 ,0, 0.3);
	position: relative;
	z-index: 999;
	margin-bottom: 150px;
`;

const IconContainer = styled.label `
	width: 54px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	cursor: pointer;
`

const SearchIcon = styled(RoundSearch) `
	font-size: 25px;
	color: #878787;
`

const Input = styled.input `
	height: 100%;
	width: 55%;
	border: none;
	cursor: pointer;
	background: ${props => props.active ? "white": "#F5F5F5"};
	padding-left: 18px;
	position: relative;
	&:focus {
		outline: none;
		color: ${props => props.theme.default.color.blackCode};
	}
`
const Form = styled.div `
	width: 100%;
	height: 100%;
	display: flex;
	cursor: pointer;
`

const Header = styled.div `
	width: 100%;
	padding-right: 30px;
	height: 53px;
	background: ${props => props.active ? "white": "#F5F5F5"};
	border-bottom: 1px solid #D7D7D7;
	border-radius: 7px 7px 0 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Text = styled.div `
	font-family: Kollektif, 'sans-serif';
	font-size: 14px;
	color: ${props => props.inverse ? props.theme.default.blackCode : "#727272"};
`;

 
const Information = styled.div `
	width: 100%;
	height: 40px;
	background: #F5F5F5;
	border-bottom: 1px solid #D7D7D7;
	display: flex;
	align-items: center;
	position: relative;
	 @media print {
     height: auto;
    }
	 @media (max-width: 700px)
          {
            	//display: block;
            	//height: auto;

          }


`;
const Item = styled.div `
width: 15%;

font-size: 16px;	
height: 1.5rem;
margin-left: 1rem;
 @media print {
     height: auto;
    }


`
const MemberList = styled.div `
	width: 100%;
	height: auto;
	background: white;
`;

const Footer = styled.div `
	width: 100%;
height: 42px;
	background: #F5F5F5;
	border-radius: 0 0 7px 7px;
	padding-left: 30px;
	display: flex;
	align-items: center;
	${Text}{
		font-family: Rubik, 'sans-serif';
		font-size: 14px;
		color: ${props => props.theme.default.color.blue};
		text-decoration: underline;
		cursor: pointer;
	}
`

const Center = styled.div `
	width: 100%;
	min-height: 200px;
	${props=>props.theme.default.utils.centerContent}
	img{
		margin: 2rem auto;
		width: 170px;
	}
	color: rgba(0,0,0,0.4);

`