import React, { Component } from 'react';
import styled from 'styled-components';

import {  Link, withRouter} from 'react-router-dom';

import firebase from "firebase"
import Loading from "Components/Molecules/Loading"
import image from "static/img/la.png"


const Login = withRouter((props)=> {




  class Modal extends Component 
  {
    constructor(props) 
    {
      
      super(props);
      this.state={
        username: "",
        password :"",
        error: null,
        loading: false,
      }
    }

  
    logIn(){
    

      if(this.state.username === "super-user@adelantat.com")
      { 
        this.setState({loading: true})  

        firebase.auth().signInWithEmailAndPassword(this.state.username, this.state.password)
          .catch((error) =>{
            // Handle Errors here.
            this.setState({error, loading: false})
            // ...
          });
      }
      else{
        alert("Este usuario no es un administrador.")
      }
    }

    render()
    {

      const close = this.props.close//?this.props.close:this.props.hideOnClick;
    
  
      return(
        <FullScreen>
          <Link to="/">
            <PseudofullScreen onClick={close}/>
          </Link>

          <Card>
        
            <NavBar>
         
              <NavTextContainer>
                <Title>
                  Bienvenido a AdelantaT-Control 👨‍✈️
                </Title>
              </NavTextContainer>

            </NavBar>

            <Container>
              {this.state.error && 
              <label>{this.state.error.code} <br/> {this.state.error.message}</label>
              }
          
              <form>
                <InputStyles 
                  onChange={(e)=>{this.setState({username:e.target.value})}}
                  value={this.state.username}
                  placeholder="Correo"/>
                <br/>
                <InputStyles 
                  onChange={(e)=>{this.setState({password:e.target.value})}}
                  value={this.state.password}
                  type="password"
                  placeholder="Contraseña"/>
    
              </form>
            
              <br/>
              <ButtonContainer>
                <Button 
                  onClick={this.logIn.bind(this)}
                  shadow>Iniciar</Button>
            
              </ButtonContainer>

            </Container>
          </Card>

          <Box>
            <img
              alt="la"
              className={"la"}
              src={image} />
                
          </Box>

          {this.state.loading && <Loading text="Iniciando sesion..."/>}
        </FullScreen>
      );
    }
  }

  return <Modal {...props}/>                
}
)
export default Login



const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: white;
  padding: 0rem;
   ${props=>props.theme.default.utils.centerContent}
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
`;

const Card = styled.div`
  margin: 0 auto;
  border-radius: 10px;
  width: 30%;
  min-height: 400px;
  color: rgba(0,0,0,0.8);
  background: white;
  position: relative;
  text-align: left;
  box-shadow: 0 0.5px 5px rgba(0,0,0,0.5);
  overflow: hidden;
   @media (max-width: 700px) {
    width: 90%;
    
  }
  z-index: 1;
 
`;


const NavBar = styled.div`
  position: relative;
  height: 96px;
  background: white;

  ${props=>props.theme.default.utils.centerContent}
`;



const Title = styled.h2`
  margin: 1rem auto;
  font-weight: 500;
  text-align: center;
  font-size: 25px;
  color: #313033;
`;

const NavTextContainer = styled.div`
  width: 90%;
  text-align: left;
`;

const Container = styled.div`
  width:90%;
  margin: 2rem auto;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-top: 1.2rem;
  text-align: right;

`;

const Button = styled.button`

  position: relative;
  padding: 0.6rem 1rem;
  
  
  margin-right: 1rem;
  border-radius: 3px;
  overflow: hidden;

  background: ${props => (!props.transparent ? props.theme.colors.green : "transparent")};
  color: ${props => (!props.transparent ? "white" : "black")};
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  border:0;

 
`;
const InputStyles = styled.input`
    padding: 0 1rem;
    margin: 0.5rem auto;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    position: relative;

    background: white;
    color: #212121;
    border: 1px solid #E5E5E5;
  
`;

//Body (size and margins) -> BodyContent (glutter and paddings) -> Real Content
const Box = styled.div`
    width: 50%;

     position: relative;
    @media (max-width: 700px) {
      width:  100%;
    }

  .la{

    position: fixed;
    width: 60%;
    top: 210px;
    left: -100px;
  
  }

 
  z-index: 0;
`;
