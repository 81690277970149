import React from 'react';
import styled from 'styled-components';
import {Route, Switch } from 'react-router-dom';
import Navbar from "Components/Molecules/Navbar";
import Sidebar from "Components/Molecules/Sidebar";
import Usuarios from "pages/Usuarios";
import Solicitudes from "pages/Solicitudes";
import Dispersion from "pages/Dispersion";
import Cobro from "pages/Cobro";
import Inicio from "pages/Inicio";
import {withFirebase} from "Files/Providers/Firebase"
import Loading from "Components/Molecules/Loading"
import { AuthenticatedRoute} from "./Utils"
import Logout from "Components/Molecules/Logout"
import Configuration from "pages/Configuration";     
             
const Routes = (props)=>{

  if(props.loading){
    return (
      <Loading 
        text="Inicializando Adelanta-T"/>
    )
  }

  return (
    <Body>
      <Container>
        <Sidebar/>
        <Switch>
          <AuthenticatedRoute 
            authenticated={props.user} 
            path={`/usuarios`} 
            component={Usuarios} />
		
          <AuthenticatedRoute 
            authenticated={props.user} 
            path={`/solicitudes`} 
            component={Solicitudes}/>
			    
          <AuthenticatedRoute 
            authenticated={props.user} 
            path={`/dispersion`} 
            component={Dispersion}/>
	
          <AuthenticatedRoute 
            authenticated={props.user} 
            path={`/cobro`} 
            component={Cobro}/>
          <AuthenticatedRoute 
            authenticated={props.user} 
            path={`/configuracion`} 
            component={Configuration}/>
          <AuthenticatedRoute 
            authenticated={props.user} 
            path={`/`} 
            component={Inicio}
          />
        </Switch>
        <Navbar />
      </Container>

      <Route  
        path="/logout"
        component={Logout} />

    </Body>


  )

}
export default withFirebase(Routes)


const Body = styled.div`
  width: 100%;
  position: fixed;
  top: 65px;
  left:0;
  bottom:0;
  right: 0;
  overflow-y: scroll;
  background: white;

  z-index: 0;    
`;

const Container = styled.div`
	width: 80%;  
	position: relative;
	z-index: -1;
	left:20%;
	bottom:0;
	right: 0rem;
	overflow: hidden;
	min-height: 100vh;
	background: #eceff1;
	@media (max-width: 700px)
	{
		left:0;
		width: 100%;    
	}
`;
    