import React  from 'react';
import styled from 'styled-components';
import Loading from "Components/Molecules/Loading";
import RoundPublic from "react-md-icon/dist/BaselineTimelapse";
import BaselineCheck from "react-md-icon/dist/BaselineCheck";
import BaselineCancel from "react-md-icon/dist/BaselineCancel";
import BaselinePayment from "react-md-icon/dist/BaselinePayment";
import BaselineMonetizationOn from "react-md-icon/dist/BaselineMonetizationOn";
import FileModal from "Components/Molecules/FileModal";
import {SliderStyle} from "./Files/styles"
import { aceptarSolicitud, cancelarSolicitud } from "Files/General/DatabaseQueries/Post/Solicitudes"
import { solicitudCobrada } from "Files/General/DatabaseQueries/Post/Cobros"
import { aceptarDispersion } from "Files/General/DatabaseQueries/Post/Dispersiones"
import Details from "./Details" ;
import {getFullSolicitud} from "Files/General/DatabaseQueries/Get/Solicitudes";
import Close from "react-md-icon/dist/BaselineClose";

const ICONS = [
  ()=><BaselineCancel/>,
  ()=><RoundPublic/>,
  ()=><BaselineCheck/>,
  ()=><RoundPublic/>,
  ()=><BaselinePayment/>,
  ()=><BaselineMonetizationOn/>,
]


function getdate(tt) {
    
  var date = new Date(tt);
  var newdate = new Date(date);
    
  if(newdate.getDate() >= 15 )
  {

    const remaindind = 30 - newdate.getDate()
    newdate.setDate(newdate.getDate() + remaindind);
     

  }
  else{
    const remaindind = 15 - newdate.getDate()
    newdate.setDate(newdate.getDate() + remaindind);
  }

  var dayweek = newdate.getDay();
  if(dayweek === 0){
    newdate.setDate(newdate.getDate() + 1);
  }
  else if(dayweek === 6){
    newdate.setDate(newdate.getDate() + 2);

  }

  return `${newdate.getFullYear()}-${newdate.getMonth()+1 < 10? "0":""}${newdate.getMonth()+1}-${newdate.getDate() < 10 ? "0":""}${newdate.getDate()}`
}

const ESTADOS = [
  "Rechazado", // 0 or undefined is cancel
  "Nueva", //1
  "Esperando usuario",
  "Dispersar",
  "Dispersado",
  "Finalizado",
]

const COLORS = [
  "red", // 0 or undefined is cancel
  "#FF8F00", //1
  "green",
  "blue",
  "green",
  "gray",
]

class SolicitudModal extends React.Component 
{
  constructor(props) 
  {
    super(props);
    this.state={
      solicitud: {},
      loading: true,
      currentFile: null,
      bancoValido: false,
      documentosValidos: false,
      buroValido: false,
      monto: 2000,
      montoDefault:5000,
      fecha: "2018-01-01",
      nota: ""
    }
  }

  async cancelarSolicitud()
  {  
    await cancelarSolicitud(this.state.solicitud)
    this.props.close() 
  } 

  async dipersarSolicitud(){
    await aceptarDispersion(this.state.solicitud);
    this.props.close()  
  }


  async cobrarSolicitud(){
    await solicitudCobrada(this.state.solicitud);
    this.props.close();  
  }

  async  aceptarNuevaSolicitud(){

    if(this.state.monto && 
        this.state.fecha &&
        this.state.bancoValido &&
        this.state.documentosValidos &&
        this.state.buroValido)
    {
      this.setState({loading: true})
      await  aceptarSolicitud({
        id:this.state.solicitud.id,
        monto: this.state.monto,
        fecha: this.state.fecha,
        nota: this.state.nota
      });     
      this.props.close()
    }
  }


  async componentDidMount(){
    const solicitud = await getFullSolicitud(this.props.id)
    const fecha = getdate(solicitud.fecha)
    this.setState({
      solicitud, 
      loading: false, 
      montoDefault: solicitud.monto,
      monto: solicitud.monto,
      fecha:  "" + fecha
    })
  }

  render()
  {
    const close = this.props.close
    const sdispersion = (()=>{
      if(this.state.solicitud.sdispersion === 1)
        return 1
      else if (this.state.solicitud.sdispersion === 2)
        return 2
      else if (this.state.solicitud.sdispersion === 0)
        return 0
      return -1
    })()
    const scobro = (()=>{
      if(this.state.solicitud.scobro === 1)
        return 1
      else if (this.state.solicitud.scobro === 2)
        return 2
      else if (this.state.solicitud.scobro === 0)
        return 0
      return -1

    })()
    
    if(this.state.loading){
      return(
        <Loading  />
      )
    }
    const defaultFallback = () => null
    const CurrentIcon = ICONS[this.state.solicitud.status] || defaultFallback
 
    return(
      <FullScreen>
        <PseudofullScreen onClick={close}/>
        <Card>
          
          <NavBar>
            <Content>
              <AlignStart>
                <Item2 hide>
                  <Row>
                    <Title2>
                      Folio #{this.state.solicitud.folio}
                    </Title2>
                    <Separator />
                  </Row>
        
                </Item2>
        
                <Item2 >
                  <PrivacyTab style={{cursor:"not-allowed"}}> 

                    <PrivacyTabContainer>
                      <IconCirlce>
                        <CurrentIcon /> 
                      </IconCirlce> 
                      <Subtitle style={{color:COLORS[this.state.solicitud.status]}}> 
                        {ESTADOS[this.state.solicitud.status]}
                      </Subtitle>
                    </PrivacyTabContainer>
                  </PrivacyTab>
                </Item2>
              </AlignStart>
    
              <AlignEnd>
      
              </AlignEnd>

            </Content>
            <Close
              onClick={close} 
              style= {{ 
                position: "absolute",
                "top": "1rem",
                "right": "1rem",
                "fontSize":"22px",
                "cursor":"pointer",
                "zIndex": 9999,

              }}

            />
          </NavBar>
          <Container>

            <Details 
              solicitud={this.state.solicitud}
              setCurrentFile = {(file, title)=>{
                this.setState({currentFile: {
                  file:`${this.state.solicitud.usuario}/${file}`,
                  title
                }})}} 
            />



            {this.state.solicitud.status === 1 &&
            <ValidationCard>
              <Title>
                Validación del administrador
              </Title>
              <br/>
              <br/>
              <br/>
       
              <div
                style={{fontSize:"21px"}}
                onClick={()=>{
                  this.setState({bancoValido: !this.state.bancoValido})}}> 
       
                <i 
                  className="material-icons" 
                  style={{"verticalAlgin":"bottom", cursor:"pointer", fontSize:"19px"}}>
                  {this.state.bancoValido?"check_box":"check_box_outline_blank"}
                </i>Los datos bancarios son válidos.</div>
              <br/>
       
              <div
                style={{fontSize:"21px"}}
                onClick={()=>{
                  this.setState({documentosValidos: !this.state.documentosValidos})}}> 
                <i 
                  className="material-icons" 
                  style={{"verticalAlgin":"bottom", cursor:"pointer", fontSize:"19px"}}>
                  {this.state.documentosValidos?"check_box":"check_box_outline_blank"}
                </i>Los documentos anexados son correctos.</div>
              <br/>
              <div
                style={{fontSize:"21px"}}
                onClick={()=>{
                  this.setState({buroValido: !this.state.buroValido})}}> 
                <i 
                  className="material-icons" 
                  style={{"verticalAlgin":"bottom", cursor:"pointer", fontSize:"19px"}}>
                  {this.state.buroValido?"check_box":"check_box_outline_blank"}
                </i>Se verificó el buró de crédito del usuario.</div>
              <br/>

              <b style={{fontSize:"21px"}}>   Monto aprobado </b> :${this.state.monto}
              <SliderStyle>
                <div className="pure-material-slider">
                  <input
                    value={this.state.monto} 
                    onChange={(e)=>{this.setState({monto:e.target.value})}}
                    type="range" 
                    step={500} 
                    min="500" 
                    max={this.state.montoDefault}/>
       
                </div>
              </SliderStyle>
              <b> Fecha : </b>(MM-DD-AAAA)<br/>
              <input
                onChange={(e)=>{this.setState({fecha:e.target.value})}}
                type="date" 
                id="start" 
                name="trip-start"
                value={this.state.fecha}
                min={""+getdate(this.state.solicitud.fecha)}
                 
              />
              <br/>
              <br/>
              <br/>
              <b>Nota de validación:</b>
              <br/>
              <br/>
              <textarea 
                style={{
                  width:"100%",
                  height: "100px",
                  borderRadius:"7px"
                }}
                onChange={(e)=>{this.setState({nota:e.target.value})}}
                value={this.state.nota}  />
            </ValidationCard>
            }
            <ButtonContainer>
              {this.state.solicitud.status === 1   &&  
                <React.Fragment>
                  <Button
                    red
                    onClick={this.cancelarSolicitud.bind(this)}
                  >Rechazar Solicitud</Button>
                  <Button 
                    disabled={
                      !(
                        this.state.monto && 
                        this.state.fecha &&
                        this.state.bancoValido &&
                        this.state.documentosValidos &&
                        this.state.buroValido
                      )}
                    shadow 
                    onClick={
                      this.aceptarNuevaSolicitud.bind(this)
                    }>Aceptar Solicitud</Button>

                </React.Fragment>
              } 

              { (this.state.solicitud.status >=  2 && this.state.solicitud.status < 4)  &&

                <React.Fragment>
                  <Button
                    red
                    onClick={this.cancelarSolicitud.bind(this)}
                  >Cancelar Proceso</Button>  
                </React.Fragment>
              }

              { (sdispersion === 1 && this.state.solicitud.status > 2)  &&

                <React.Fragment>

                  <Button 
                    shadow 
                    onClick={
                      this.dipersarSolicitud.bind(this)
                    }>Dispersar</Button>

                </React.Fragment>
              }
              {(scobro === 1 && this.state.solicitud.status >= 4)   &&  
                <Button 
                  shadow 
                  onClick={
                    this.cobrarSolicitud.bind(this)
                  }>Cobrar</Button>
              }
            </ButtonContainer>
          </Container>

          {this.state.currentFile && 
          <FileModal  
            close = {()=>{this.setState({currentFile: null})}}
            {...this.state.currentFile}/>}
        </Card>
      </FullScreen>
    );
  }
}
export default SolicitudModal


export const UserCirlce = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: ${props => props.src? "url(" +props.src+ ")": "url('http://placehold.it/50x50')"};
  display: inline-block;
  margin: 0 0.1rem;
`;

export const IconCirlce = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  ${props=>props.theme.default.utils.centerContent}
  overflow: none; 
  font-size: 24px;
  background: #DFDFDF;
`;

export const PrivacyTab = styled.button`
 position: relative;
 border: none;
  border-radius: 22px;
  height: 40px;
  font-weight: 400;
  font-size:13px;
  color:#444444;
  background:white;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.theme.default.utils.rowContent}

${IconCirlce}{
  
  height: 30px;
  width: 30px;
}
`;

export const PrivacyTabContainer = styled.div`
  height: 75%;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.theme.default.utils.rowContent}
  width:100%;
`;

export const PrivacyText = styled.div`
    margin: 0 0.4rem;
`;



const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.4);
  padding: 0rem;
  padding-top: 1%;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
`;

const Card = styled.div`
  margin: 1rem auto;
  border-radius: 7px;
  overflow: hidden;
  width: 60%;
  padding-bottom: 1rem;
  color: rgba(0,0,0,0.8);
  background: white;
  position: relative;
  text-align: left;
  box-shadow: 0 0.5px 10px rgba(0,0,0,0.3);
  
    @media (max-width: 700px) {
    width: 90%;
    
  }
 
`;

const NavBar = styled.div`
 position: absolute;
width: 100%;
  top:0;
  left:0;
  margin-top: 0;
  width: 100%;
  height: 65px;
  position: relative;
  background: white;

  ${props=>props.theme.default.utils.centerContent}

`;



const Title = styled.h2`
  margin: 0;
  font-weight: 600;
  text-align: center;
  font-size: 25px;
  color: #202124;
`;

const Subtitle = styled.h4`
  font-weight: 500;
  text-align: left;
  color: #5F6469;
  font-size: 16px;
  margin: 0 0.5rem; 

`;



const Container = styled.div`
  width:90%;
  margin: 2rem auto;
   padding-top: 65px;
   text-align: center;
`;



const Content = styled.div`
 position: relative;
 width: 100%;
 background: #F8F9FA;
    ${props=>props.theme.default.utils.rowContent}    
    padding: 1rem;
   @media (max-width: 700px) {
          display: inline-block;
         }
`;


const Row = styled.div`
  ${props=>props.theme.default.utils.rowContent}
`;

const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;

    min-width: 0;
    height: 100%;

    flex: 1;
    z-index: 1;
    
     @media (max-width: 700px) {
          display: block;
         }

`;

const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
  
`;

const AlignEnd =  styled(_Section)`
    justify-content: flex-end;
    order: 1;
  
`;


const Title2 = styled.div`
  margin: 0;
  font-weight: 500;
  text-align: left;
  font-size: 25px;
  color: #202124;
`;


const Separator = styled.div`
  color: #757575;
  position: relative;
  border-left: 1px solid #757575;
  width: 2px;
  margin: 0 0.5rem;
  height: 2rem;
  opacity: 0.5;
`;



const Item2 = styled.div`
  position: relative; 
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
  text-align: left;

  min-width: 45px; 
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               display: inline;
           }      
    `}

     ${props=>props.hide && `
         @media (max-width: 700px) {
          display: none;
         }
      `}
`;


const ValidationCard = styled.div`
  width: 90%;
  padding: 2rem;
  text-align: left;
  margin: 0 auto;
  box-shadow: 0 0.3px 5px rgba(0,0,0,0.2);
`;



const ButtonContainer = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-top: 2.3rem;
  text-align: center;

`;

const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  margin-right: 1rem;
  border-radius: 7px;
  overflow: hidden;
  background: ${props => !props.red?props.theme.colors.green: "none"};
  color: ${props => !props.red?"white": "red"};
  cursor: pointer;
  font-weight: normal;
  font-size: 18px;
  border:${props => props.red?"solid 1px red": "0"};
     @media (max-width: 700px) {
          margin: 1rem auto;
          display: block;
         } 

    ${props=>props.disabled && `  
  background:#676C72;
  cursor: no-allowed;
  `}
`;
