import {elevation} from './Material/Elevations'
import media  from './Media';


const theme = {
//Principal colors
  primary: '#212121',
  secondary: '#3367D6',
  background: '#dfdfdf',
  contrast: 'white',
  auxiliar: '#414141',
  primaryText: "white",
  secondaryText:"#6D6D6D",

  color:{

    "blackCode":"#1D1D1D",  
    "blackNotebook":"#171717",
    "blackSeparator":"#1E1E1E",
    
 

    "blue":"#1041FB",

    "primary":"#1041FB",
    "grey": "#EEEEEE",
    "background":"#EEEEEE",
    "greyBackground":"#EEEEEE",

    "white": "#fafafa",

    "navbar":"#fafafa",
    "navbarText":"#676C72",
    "navbarSeparator": "#E8EAED",

  },

  //Functions
  elevation,
  media,

  utils: {
    centerContent:` 
      display: flex;
      flex-direction: column;
      justify-content: center;

      text-align:center;
      align-items: center;
    `,

    fullscreen:` 
      position: fixed;
      top:0rem;
      left:0rem;
      right:0rem;
      bottom:0rem;
      width:100%;
      height:100vh;
      z-index: 999999999999999999999999999999999999999999999;
      background: rgba(33,33,33,0.8); 
    `,
    rowContent:` 
    display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-content: flex-end;
      flex-flow: row wrap;
    `
  },
  //Vars and const
  const: {
    "navbarHeight":48.48, //px size
    "container": 70, // %

  }
};



export default theme;

