import React, { Component } from 'react';
import styled ,{keyframes, css, ThemeProvider}from 'styled-components';
import ReactDOM from 'react-dom';
import { Redirect, Link,BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect, Provider } from "react-redux";
import { combineReducers, applyMiddleware, createStore, bindActionCreators  } from "redux";
import { withRouter  } from "react-router-dom";
import BaselineMoreVert from "react-md-icon/dist/BaselineMoreVert";
import MorePopover from "./MorePopover"
import Sidebar from "Components/Molecules/Sidebar/Small"

const _ = require('lodash');


//Code
const UserCirlce = styled.div`
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: ${props => props.src? "url(" +props.src+ ")": "url('http://placehold.it/50x50')"};
  `;

const Logo = styled.img`
      padding-top:3px;
      width: 23px;
      margin-left: -10px;
  `;

const Nav = styled.nav`
      top:0;
      left:0;
      position: fixed;
      overflow: hidden;
      width: 100%;
      background: white;
      color: ${props=>props.theme.default.color.navbarText};
      height: 65px;
      ${props=>props.theme.default.utils.rowContent}
      
      border: solid 1px rgba(0,0,0,0.1);
  `;

const NavContent = styled.nav`
      ${props=>props.theme.default.utils.rowContent}
      width: 100%;
      margin: 0 20px;
  `;

const _Section = styled.div`  
      display: inline-flex;
      align-items: flex-start;
      justify-content: center;
      min-width: 0;
      height: 100%;
      flex: 1;
      

  `;

const AlignStart = styled(_Section)`
      justify-content: flex-start;
      order: -1;
  `;

const AlignEnd = styled(_Section)`
      justify-content: flex-end;
      order: 1;
  `;

const Item = styled.div`
      position: relative; 
      height: 65px;
      min-width: 45px; 
      ${props=>props.theme.default.utils.centerContent}
      ${props=>props.cursor && "cursor: pointer;"}
      ${props=>props.margin && "margin: 0 0.5rem;"}
      ${props=>props.hideBig && `
          display: none;
          @media (max-width: 700px)
          {
              ${props.theme.default.utils.centerContent}
          }
      `}
      ${props=>props.hide && `
          @media (max-width: 700px)
          {
            display: none;
          }
      `}
      font-size: 18px;
      a{
          text-decoration: none;
      }
  `;
const Separator = styled.div`
    color: #757575;
    position: relative;
    border-left: 1px solid #757575;
    width: 2px;
    height: 1.2rem;
    opacity: 0.5;
  `;

const Icon = styled.i`
    vertical-align: bottom;
  `;

const logo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAdCAYAAAC9pNwMAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAQ8SURBVEhL5ZZ7bFNVHMdRo4mRf4wm+peJrz98Jv41EMU3BhOijD8U0UQRlGBAfGVqjEQNRokIq2zEjQUEJyN2Mti6MULHgJWWdRvr2nXdo3Prpnt0tHt0r27nfj3nd0/X3t3bdjMm/uEnOX/c3/nd87nnfZfgP2LRYkUBAm0R1FUFUflrL2yWAbQ1jmByfFZmLIwFi711YXz1xhWsvv0MMpaU6srKGy1473kHLD/3gDH+dWlIKx4NRfH5+nosuyYuefWhany4phZfbGhAVmYdtjxxCStusMzVr3+wGm5HSLZgTEpxu2sEmXdbZY/KYfqoGYH2iKzVMjYcRUleN9beqeaLDzlV0C1r9SQVi3l7cmk5NbJpeQ0G/5yUNamZiTLkfNoy1/uj33XIGi2G4vDgNF684yy9uGO1A1MTi1s4AnPOH/T+smtLcbG0X0bjGIrFnIqXXn/k/KJXayL7s7zUznO3nEZkZEZGVXRiX/0wMvhCWn5dGTrcozL6z2CzCi1EIc/9rEVGVXTibza7KPHbLU0yosdeMYDoFJNPqXFag9TeszefhpKwzTRicTjE9mlHU/Lerr3LSj0RC5B3C8pfV8C8J8Gaf4fSWg4l1CkzVV6+7xy16bJdlZF54oGeSUpYdWuljBgjxCJvzW2nMFbzC5h9P1htHlh9IZjjAD0r/ireE3VUdm91U37hHj89CzRitz1ECRszLsqIMTHxZdNBVdLK8/uD/MuHgL4+MHep+jE+C43I0d0dlC8WWwyNuPpEHyV8/JJTRowR4jfvN6vSFqsq1JQgmKdclXtLUHGki9rdtcklW5gnji2Ed5+xy4gxQvzDK3xYecPoaTcQ88JHgDWVUE778cN4+qaT2PdBs2xhntjvGSXxunv5/KRAiE2vqXNrKA7yRTQ+ASXYpvaaF++hPJiz41tKIxbH3VNLK0jeH5iQUT1CvOOxYmpQN9ShYT6v6rZR/NVzYiq9dRQXaMSCrEwniYuytVsikYNfttJlwZqK+CrOgdLliYtn1ZWsRPhQ23M0YiUySHUCndhROUjiF/h+noikOS4nw2DOAi7PhdLJF86YenMp4YAaj0kv/QjmOkZ1MXRiwTsrbSQXF386lHHeM6fYVrx33jK+lw/HhaLUZCNa/AmUofgeFhiKPfwSX3F9GcmLD3TJaHLEEDJnvlaYIJ1t/E1mxjEUC8QvjBCLC6Pg6zY6TlNB8toEuc2kSmsP8XmflllxkooF4g/iUdnztx+3wdfAV2wKRnt6MWbNlXI+r/VHoEzx89yAlGJBw4WrWHdP1VzvNzx8Hnvf96Dwez9K8rtx3NSJvJ0+bFvloB++jQ+YETD/hBnfGd7TqGxFT1qxIDrNcGyvn642+oAkRfwQ7nqrEUN9U/LN5CxIHEMcMJ7LYZzgP3X5O1uxZ7sHufz/qmhfJ23DtNsvgUWJ/03+b2Lgb9qbRf05zl9tAAAAAElFTkSuQmCC"




class Header extends Component
{
  constructor(props) 
  {
    super(props)
    this.state = {
      sidebar: false
    }
  }
  
  conponentDidUpdate(){
      
  }
  

  render() {



  
    return (  
      <React.Fragment>  
        <Nav >
          <NavContent>
            <AlignStart>
              <Item hideBig>
                <a onClick={()=>{
                  this.setState({sidebar:true})}}>
                  <i className="material-icons">menu</i>
                </a>
              </Item>

              <Item >
                <Link to="/dashboard">
                  <Logo  src={logo}/>
                </Link>
              </Item>
              <Item  
                style={{ 
                     
                  fontWeight:"500"
                }}>
                Administrador
              </Item>
            </AlignStart>

            <AlignEnd> 

              <Item>
                <MorePopover>
                  <BaselineMoreVert
                    style={{
                      "cursor":"pointer", 
                      "fontSize":"22px"
                    }} 
                  />
                </MorePopover>

              </Item>
                
            </AlignEnd>
          </NavContent>
           
        </Nav>

        {this.state.sidebar && <Sidebar 
          close={()=>{this.setState({sidebar: false})}}/>}
      </React.Fragment>
    );
  }
}



export default withRouter(Header)