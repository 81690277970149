import React from 'react';
import FirebaseProvider from "./Files/Providers/Firebase"
import {css, ThemeProvider}from 'styled-components';
import Routes from "./Files/Settings/Routes";
import {BrowserRouter } from 'react-router-dom';
import theme from './Files/Settings/Styles/themeApp';

const sizes = {
  desktop: 992,
  tablet: 768,
  phone: 500
}

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
		@media (max-width: ${sizes[label] / 16}em) {
			${css(...args)}
		}
	`
  return acc
}, {})
 
const style =  {
  media,
  colors: {
    green: "#00C853",
    gray1: "#E2E2E2",
  },
  primary: '#212121',
  secondary: '#3367D6',
  background: '#dfdfdf',
  contrast: 'white',
  auxiliar: '#414141',
  primaryText: "white",
  secondaryText:"#6D6D6D",
  color:{
    "blackCode":"#1D1D1D",  
    "blackNotebook":"#171717",
    "blackSeparator":"#1E1E1E",
    "blue":"#1041FB",
    "primary":"#1041FB",
    "grey": "#EEEEEE",
    "greyText": "#676C72",
    "background":"#EEEEEE",
    "greyBackground":"#EEEEEE",
    "white": "#fafafa",
    "navbar":"#fafafa",
    "navbarText":"#676C72",
    "navbarSeparator": "#E8EAED",
  }
}

const App = ()=> {
  return(
    <FirebaseProvider>
      <ThemeProvider  
        theme={{...style, default: theme}}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </FirebaseProvider>
  )              
}
export default App