import React, { Component } from 'react';
import styled from 'styled-components';
import Loading from "Components/Molecules/Loading";
import Close from "react-md-icon/dist/BaselineClose";
import {getFile} from "Files/General/DatabaseQueries/Get/Files"

class FileModal extends Component 
{
  constructor(props) 
  {
    super(props);
    this.state={
      url: "",
      loading: true
    }
  }
 
  async componentDidMount(){
  		getFile(this.props.file, 
      (url) => {
  				this.setState({url, loading: false})
  		}) 
  }
  isImg(name){
  	return name.includes(".jpg") || name.includes(".png")
  }
  render()
  {
    const close = this.props.close;
    if(this.state.loading){
      return(
        <Loading text="Abriendo Archivo." />
      )
    }

    if(!this.state.loading && !this.state.url)
    {
    	alert("Falló la descarga del archivo.")
    	this.props.close()
    }

    return(
      <FullScreen>
        <PseudofullScreen onClick={close}/>
        <Card>
          <div style={{position: 'relative'}}>
            <NavBar>
              <Content>
                <AlignStart>
                  <Item2>
      
                    <Title2>
                      {this.props.title}
                    </Title2>
 
                  </Item2>    
                </AlignStart>
                <AlignEnd>
                </AlignEnd>
              </Content>
         
              <Close
                onClick={close} 
                style= {{ 
                  position: "absolute",
                  "top": "1rem",
                  "right": "1rem",
                  "fontSize":"22px",
                  "cursor":"pointer",
                  zIndex: 2222,
                }}
             
              /> 
            </NavBar>
          </div>
          <Container>
            {!this.isImg(this.props.file)?
              <iframe 
                title="documents"
                src={this.state.url}
              />:
              <img
                src={this.state.url}
                alt="documents"/> }
          </Container>
        </Card>
      </FullScreen>
    );
  }
}
      
export default FileModal


const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.4);
  padding: 0rem;
  padding-top: 1%;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
`;

const Card = styled.div`
  margin: 1rem auto;
  border-radius: 7px;
  overflow: hidden;
  width: 80%;
  padding-bottom: 1rem;
  color: rgba(0,0,0,0.8);
  background: white;
  position: relative;
  text-align: left;
  box-shadow: 0 0.5px 10px rgba(0,0,0,0.3);
  
    @media (max-width: 700px) {
    width: 90%;
    
  }
  text-align:center;
 img{
 	width:90%;
 	margin: 0 auto;
 }
 iframe{
  width:100%;
  height:400px;
 }
`;


const NavBar = styled.div`
 position: relative;
width: 100%;
  top:0;
  left:0;
  width: 100%;
  height: 65px;

  background: white;

  ${props=>props.theme.default.utils.centerContent}
`;

const Container = styled.div`
  width:90%;
  margin: 2rem auto;
   padding-top: 65px;
   text-align: center;
`;





const Content = styled.div`
 position: relative;
 width: 100%;
 background: #F8F9FA;
    ${props=>props.theme.default.utils.rowContent}    
    padding: 1rem;
   @media (max-width: 700px) {
          display: inline-block;
         }
`;


const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;

    min-width: 0;
    height: 100%;

    flex: 1;
    z-index: 1;
    
     @media (max-width: 700px) {
          display: block;
         }

`;

const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
  
`;

const AlignEnd =  styled(_Section)`
    justify-content: flex-end;
    order: 1;
  
`;




const Title2 = styled.div`
  margin: 0;
  font-weight: 500;
  text-align: left;
  font-size: 25px;
  color: #202124;
`;



const Item2 = styled.div`
  position: relative; 
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
  text-align: left;

  min-width: 45px; 
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               display: inline;
           }      
    `}

     ${props=>props.hide && `
         @media (max-width: 700px) {
          display: none;
         }
      `}
`;