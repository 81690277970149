import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import firebase from "firebase";
import Rebase from 're-base';
import Loading from "Components/Molecules/Loading"

class Config extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      config: {}, 
      staging: false,
      admin_autorizados: "",
      admin_notify: "" 
    }
    this.base = Rebase.createClass(firebase.database());
  }

  componentDidMount(){

    this.shutDownProfiles = this.base.syncState(`admin`, {
      context: this, 
      state: "config",
      then(){
        this.setState({
          admin_notify: this.state.config.admin_notify,
          admin_autorizados: this.state.config.admin_autorizados,
          loading: false
        })    
      }

    });
  }
      
  componetWillUnmount()
  {
    this.shutDownSolicitudes();

  }
 

  render() {

    if(this.state.loading)
      return <Loading />
    return(  
      <Body>
        <h4>Configuración</h4>
        <Card>
          <h1>Administrador</h1>
          <hr/>
          <h2>
            Administradores autorizados
          </h2>
          Solo los correos en esta lista pueden ingresar al sistema de administradores.<br/> (El usuario debe tener una cuenta activa en el sistema para poder inicar sesion)
          <br/>
          <br/>

          <Textarea
            onChange={(e)=>{
              this.setState({admin_autorizados:e.target.value, staging: true})
            }} 
            value={this.state.admin_autorizados}
          />
          <h2>
            Notificaciones por Email
          </h2>
          Los correos llegaran a las siguiente cuentas, cuando un usuario, solicite y acepte las condiciones del adelanto.
          <br/>
          <br/>
          <Textarea 
            onChange={(e)=>{
              this.setState({
                admin_notify:e.target.value, 
                staging: true
              })
            }} 
            value={this.state.admin_notify}/>

          <Button
            disabled={!this.state.staging}
            onClick={()=>{
               
              this.setState({staging:false, config:{...this.state.config, 
                admin_autorizados: this.state.admin_autorizados,
                admin_notify: this.state.admin_notify
              }})
            }}>Guardar</Button>
        </Card>
          

        <Card>
          <h1>Usuarios</h1>

          <hr/>

          <h2>
            Notificaciones
          </h2>
          <div
            onClick={()=>{
              this.setState({config:{...this.state.config, 
                USERS_SMS: !this.state.config.USERS_SMS}})
            }}>

            <i 
              className="material-icons" 
              style={{"verticalAlgin":"bottom", cursor:"pointer"}}>
              {this.state.config.USERS_SMS?"check_box":"check_box_outline_blank"}
            </i>Enviar notificacion por SMS usuario cunado se autoriza, dispersa y cobra el adelanto.</div>

        </Card>
        
      </Body>
          
    )
  }
}

export default withRouter(Config)


const Body = styled.div`
  width: 90%;
  padding: 1rem;
  margin: 0 auto;
  overflow: none;
  @media (max-width: 700px){
    width: 100%;
  } 
`;

const Row = styled.div`
  margin: 0 auto;
`;

const Textarea = styled.textarea`
  width: 90%;
  min-height: 100px;
  padding: 1rem;
  border-radius: 7px;
  border: solid 1px rgba(0,0,0,0.2);
`;

const Card = styled.div`
  width: 100%;
  height: auto;
  background: white;
  border-radius: 7px;
  padding: 2rem;
  box-shadow: 0 0.5px 5px rgba(0,0,0,0.2);
  margin: 1rem auto;
  h1,h2{
    text-align: left;
    font-weight: 500;
  }
  i{
    vertical-align: bottom;
  }
  text-align: left;
`;
const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  margin-right: 1rem;
  border-radius: 7px;
  overflow: hidden;
  background: ${props => !props.disabled?props.theme.colors.green: "gray"};
  color: ${props => !props.disabled?"white": "rgba(0,0,0,0.3)"};
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  border:${props => props.red?"solid 1px red": "0"};
  @media (max-width: 700px) {
    margin: 1rem auto;
    display: block;
  } 
`;