import React from 'react';
import styled from 'styled-components';
import { dateFormatter } from 'utils/dateFormatter';
import { formatMoney } from 'utils/formatMoney';

const Details = (props)=> {
  const {
    folio = "02323",
    fecha = "Hace unos momentos",
    banco = "BANAMEX",
    clabe = "34569853579309832",
    convenio = "1202",
    cuenta = "5111-2348-2332-2484",

    name = "Usuario",
    apellido = "AdelantaT",
    telefono = "+52093293133",
 

    //This is just metadata
    files_adress = "",
    files_id = "",
    files_nomina = "",

    //This is just metadata
    monto = "500",
    montoAprobado ,
    mail,

    fechaAprobada,
  } = props.solicitud 

  return (     
    <Button>
      <Content>
        <Item style={{width: "33%"}}> 
          <Title>Datos de solicitud</Title>
        </Item>
        <Item style={{width: "64%"}}> 

          <Subtitle>Folio: #{folio}</Subtitle>
          <Subtitle>Fecha: {dateFormatter(fecha)}</Subtitle>
          <Subtitle>Cantidad solicitada: ${formatMoney(monto)}</Subtitle>
          { montoAprobado && <Subtitle>Monto aprobado: ${formatMoney(montoAprobado)}</Subtitle>  }
          { montoAprobado && <Subtitle>Total a cobrar: ${formatMoney(parseFloat(montoAprobado) + parseFloat(montoAprobado)*0.1)}</Subtitle>  }
          { fechaAprobada && <Subtitle>Fecha de cobro: {fechaAprobada}</Subtitle>  }
          { convenio && <Subtitle>Convenio: {convenio}</Subtitle>  }
          
        </Item>

      </Content>



      <Content>


        <Item style={{width: "33%"}}> 
          <Title>Datos del solicitante

          </Title>

        </Item>

        <Item style={{width: "64%"}}> 
          <Subtitle>Nombre: {name}  {apellido}</Subtitle>
          <Subtitle>Cel: +52{telefono}</Subtitle>
          <Subtitle>E-Mail: {mail}</Subtitle>
        </Item>
      </Content>
      <Content>

        <Item style={{width: "33%"}}> 
          <Title>Datos bancarios</Title>
        </Item>

        <Item style={{width: "64%"}}> 


          <Subtitle>Banco: {banco}</Subtitle>
          <Subtitle>N° de cuenta: {cuenta}</Subtitle>
          <Subtitle>CLABE:  {clabe}</Subtitle>
      
        </Item>

      </Content>
      <Content>
        <Item style={{width: "33%"}}> 
          <Title>Archivos Compartidos</Title>
        </Item>
        <Item style={{width: "64%"}}> 
          <Subtitle>
            <a
              href="#"
              onClick={(e)=>{
                e.preventDefault();
                props.setCurrentFile(
                  files_id,
                  "👤 Identificación"
                )
              }}>Identificación </a></Subtitle>
          <Subtitle>
            <a
              href="#"
              onClick={(e)=>{
                e.preventDefault();
                props.setCurrentFile(
                  files_adress,
                  "✉️ Comprobante de domicilio"
                )
              }}

            >Comprobante de domicilio </a></Subtitle>

          {props.solicitud.files_nomina &&     
          <Subtitle>
            <a
              href="#"
              onClick={(e)=>{
                e.preventDefault();
                props.setCurrentFile(
                  files_nomina,
                  "💳 Comprobante de nómina"
                )
              }}
 
            >Comprobante de Nomina </a></Subtitle>}
        </Item>
      </Content>
    </Button>   
  );             
}

export default Details


const Button = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  position: relative;
  margin: 1rem auto;
  background: white;
  color: #212121;
`; 

const Content = styled.div`
  ${props=>props.theme.default.utils.rowContent}
  width: 95%;
  margin: 0 auto;
  padding: 1rem;
  border-bottom: solid 1px rgba(0,0,0,0.2);
  @media (max-width: 700px) {
    display: block;
  }
`;



const Item = styled.div`
  position: relative; 
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
  text-align: left;
  min-width: 45px; 
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               display: inline;
           }      
    `}

     ${props=>props.hide && `
         @media (max-width: 700px) {
          display: none;
         }
      `}


         @media (max-width: 700px) {
          margin-top: 1rem; 
         }
`;

const Title = styled.div`
width: 100%;
display: block;
font-size: 19px;
font-weight: 600;
color: #313033;
text-align: left;
`;

const Subtitle = styled.div`
width: 100%;
display: block;
font-size: 16px;
color: #5F6468;
font-weight: 300;
`;