import React, {Component } from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
class Sidebar extends Component {

  constructor(){
    super();
    this.state = {
      solicitudes: false,
      cobros: false,
      dispersiones: false,
    }
  }
  render()
  {
    const tab = this.props.location.pathname.split("/").length
    return (
      <SidebarWrappe 
        className = "zindex">

        <Head active = {this.props.location.pathname === ("/")}>
          <Link to="/">
            <Icon 
              className="material-icons">home</Icon>Inicio
          </Link>
        </Head>

        <Head active = {this.props.location.pathname.includes("/usuarios")}>
          <Link to="/usuarios">
            <Icon 
              className="material-icons">people</Icon>Usuarios
          </Link> 
        </Head>

        <Head
          onClick={()=>{this.setState({solicitudes: !this.state.solicitudes})}}

        >
            
          <Icon 
            className="material-icons">playlist_add</Icon>Solicitudes
          <Icon 
            style={{fontSize:"18px"}}
            className="material-icons">{this.state.solicitudes?"arrow_drop_up":"arrow_drop_down"}</Icon>
          
        </Head>

        {this.state.solicitudes && <Ul>
          <Li>
            <Head active = {this.props.location.pathname.includes("/solicitudes") && tab === 2}>
              <Link to="/solicitudes">
                Recientes
              </Link> 
            </Head>
          </Li>
          <Li>
            <Head active = {this.props.location.pathname.includes("/solicitudes/aprobadas")}>
              <Link to="/solicitudes/aprobadas">
                Autorizadas
              </Link> 
            </Head>
          </Li>
          <Li>
            <Head active = {this.props.location.pathname.includes("/solicitudes/dispersion")}>
              <Link to="/solicitudes/dispersion">
                En dispersión
              </Link> 
            </Head>
          </Li>

          <Li>
            <Head active = {this.props.location.pathname.includes("/solicitudes/cobro")}>
              <Link to="/solicitudes/cobro">
                En Cobros
              </Link> 
            </Head>
          </Li>
          <Li>
            <Li>
              <Head active = {this.props.location.pathname.includes("/solicitudes/canceladas")}>
                <Link to="/solicitudes/canceladas">
                  Rechazados
                </Link> 
              </Head>
            </Li>
            <Head active = {this.props.location.pathname.includes("/solicitudes/finalizadas")}>
              <Link to="/solicitudes/finalizadas">
                Finalizado
              </Link> 
            </Head>
          </Li>
        </Ul>}

        <Head 
          onClick={()=>{
            this.setState({dispersiones: !this.state.dispersiones})
          }}>
            
          <Icon 
            className="material-icons">playlist_add_check</Icon>Dispersiones 
          <Icon 
            style={{fontSize:"18px"}}
            className="material-icons">{this.state.dispersiones?"arrow_drop_up":"arrow_drop_down"}</Icon>
          
        </Head>

        {this.state.dispersiones && <Ul>
          <Li>
            <Head active = {this.props.location.pathname.includes("/dispersion") && tab === 2}>
              <Link to="/dispersion">
                Por dispersar
              </Link> 
            </Head>
          </Li>
          <Li>
            <Head active = {this.props.location.pathname.includes("/dispersion/dispersados")}>
              <Link to="/dispersion/dispersados">
                Dispersados
              </Link> 
            </Head>
          </Li>
          <Li>
            <Head active = {this.props.location.pathname.includes("/dispersion/canceladas")}>
              <Link to="/dispersion/canceladas">
                Cancelados
              </Link> 
            </Head>
          </Li>
                     
        </Ul>}

        <Head 
          onClick={()=>{this.setState({cobros: !this.state.cobros})}}


        >
            
          <Icon 
            className="material-icons">monetization_on</Icon>Cobros                            
          <Icon 
            style={{fontSize:"18px"}}
            className="material-icons">{this.state.cobros?"arrow_drop_up":"arrow_drop_down"}</Icon>
          
        </Head>

        {this.state.cobros &&  <Ul>
          <Li>
            <Head active = {this.props.location.pathname.includes("/cobro") && tab === 2}>
              <Link to="/cobro">
                Por cobrar
              </Link> 
            </Head>
          </Li>
          <Li>
            <Head active = {this.props.location.pathname.includes("/cobro/cobrados")}>
              <Link to="/cobro/cobrados">
                Cobrados
              </Link> 
            </Head>
          </Li>
                            
                              
        </Ul>}
          
      </SidebarWrappe>
    )}
}
        
export default withRouter(Sidebar)

const Icon = styled.i`
        vertical-align: bottom;
        margin-right: 1rem;
      `;

const SidebarWrappe = styled.div`
  &::-webkit-scrollbar-track
  {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);

    background-color: #f3f3f3;
  }

  &::-webkit-scrollbar
  {
    width: 9px;
    height: 9px;
    background-color: #f3f3f3;
  }
  &::-webkit-scrollbar-thumb
  {
    opacity: 0.2;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(0,0,0,0.2);
  }
        width: 20%;
        position: fixed;
        top: ${64}px;
        left:0;
        bottom:0;
        font-family: 'Rubik', sans-serif;
        background: #fafafa;

        box-shadow: 0 0.5px 5px rgba(0,0,0,0.3);
        z-index: 0;
        padding-top: 2rem; 
        overflow-y: scroll;
          @media (max-width: 700px)
          {
            display: none;
          }
      `;

const Ul = styled.ul`
          list-style:  none;
          font-size: 16px;
          width: 100%;
          overflow: hidden;
          font-weight: 400;
      `;

const Li = styled.li`
        width: 100%;
        padding: 0rem 0;
      `;

const Head = styled.p`
        margin-top: 0.1rem;
        text-decoration: none;
        color:${props=>props.active?props.theme.colors.green:"#7D7582"} ;
        font-size: 15px;
        padding: 0 1rem;
        cursor: pointer;
        position: relative;
        a{
            text-decoration: none;
            color:${props=>props.active?props.theme.colors.green:"#7D7582"} ;
        }
      `;


