export const filterByKey = (items, key, ascending) => {
  if(ascending)
  {
    return items.sort((a, b) => {
      if(a[key] < b[key]) return -1;
      if(a[key] > b[key]) return 1;
      return 0;
    })
  }
  else 
  {
    return items.sort((a, b) => {
      if(a[key] < b[key]) return 1;
      if(a[key] > b[key]) return -1;
      return 0;
    })
  
  }
}