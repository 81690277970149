import React, { Component } from 'react';
import styled from 'styled-components';
import SolicitudCard from "Components/Molecules/SolicitudModal";
import RoundPublic from "react-md-icon/dist/BaselineTimelapse";
import BaselineCheck from "react-md-icon/dist/BaselineCheck";
import BaselineCancel from "react-md-icon/dist/BaselineCancel";
import BaselinePayment from "react-md-icon/dist/BaselinePayment";
import BaselineMonetizationOn from "react-md-icon/dist/BaselineMonetizationOn";
import { formatMoney } from 'utils/formatMoney';
import { dateFormatter } from 'utils/dateFormatter';

const ICONS = [
  ()=><BaselineCancel/>,
  ()=><RoundPublic/>,
  ()=><BaselineCheck/>,
  ()=><RoundPublic/>,
  ()=><BaselinePayment/>,
  ()=><BaselineMonetizationOn/>,
]

const ESTADOS = [
  "Rechazado", // 0 or undefined is cancel
  "Nueva", //1
  "Esperando usuario",
  "Dispersar",
  "Dispersado",
  "Finalizado",
]
const COLORS = [
  "red", // 0 or undefined is cancel
  "#FF8F00", //1
  "green",
  "blue",
  "green",
  "gray",
]
	

class Modal extends Component 
{
  constructor(props) 
  {
    super(props);
    this.state={
      showSolicitud: false,
      showPropuesta: false,
      loading: true
    }
  }

  render()
  {
    const {
      status= 1, 
      monto="", 
      montoAprobado,
      fechaAprobada,
      folio,
      fecha,
      convenio} = this.props;

    const CurrentIcon = ICONS[status]
    return (
      <React.Fragment>
        <Button zindex = {this.state.showSolicitud || this.state.showPropuesta}>
          <Content>
            <AlignStart>
              <Item2
                hide
                style={{width: "40%"}}>
                <Row>
                  <Title>
                    Folio #{folio}
                  </Title>
                  <Separator />
                </Row>        
              </Item2>

              <Item2 style={{width:"300px"}}>

                <PrivacyTab style={{cursor:"not-allowed"}}> 

                  <PrivacyTabContainer>
                    <IconCirlce>
                      <CurrentIcon/> 
                    </IconCirlce> 
                    <Subtitle style={{color:COLORS[status]}}> {ESTADOS[status]}
                    </Subtitle>
                  </PrivacyTabContainer>

                </PrivacyTab>
              </Item2>
            </AlignStart>
    
            <AlignEnd>
      
            </AlignEnd>

          </Content>

          <Content2>
            <AlignStart>
              <Item2 style={{width: "35%"}}> 
                <Title2>
                  Datos de solicitud
                </Title2>
  
              </Item2>
              <Item2 style={{width: "60%"}}> 
                <Subtitle2>Folio: #{folio}</Subtitle2>
                <Subtitle2>Fecha: {dateFormatter(fecha)}</Subtitle2>
                <Subtitle2>Cantidad solicitada: ${formatMoney(monto)}</Subtitle2>
                { montoAprobado && <Subtitle2>Monto aprobado: ${formatMoney(montoAprobado)}</Subtitle2>  }
                { fechaAprobada && <Subtitle2>Fecha de pago: {fechaAprobada}</Subtitle2>  }
                { convenio && <Subtitle2>Convenio: {convenio}</Subtitle2>  }
              </Item2>



            </AlignStart>
            <AlignEnd>
      
            </AlignEnd>

  

          </Content2>

          <Item style={{width:"100%", borderTop:"solid 1px rgba(0,0,0,0.1)", height:"60px"}}> 
            <a
              href="#" 
              onClick={(e)=>{
                e.preventDefault()
                this.setState({showSolicitud: true})}}
              style={{color:"#00C853"}}
            >
              Abrir solicitud
     
            </a>
          </Item>

        </Button>

        {this.state.showSolicitud && <SolicitudCard 
          close={()=>{this.setState({showSolicitud: false})}}
          id={this.props.id}/>}
      </React.Fragment>
    );   
  }
}



export default Modal


const Button = styled.div`
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    z-index: 0;
    border-radius: 5px;
    position: relative;
    margin: 1rem auto;
    background: white;
    color: #212121;
     box-shadow: 0 0.3px 5px rgba(0,0,0,0.2);
    
      @media (max-width: 700px) {
               
           height: auto;
          
           }
          
 z-index:1;
  
`;

const Content = styled.div`
 background: #F8F9FA;
    ${props=>props.theme.default.utils.rowContent}    
    padding: 1rem;
   @media (max-width: 700px) {
          display: inline-block;
         }
`;


const Row = styled.div`
  ${props=>props.theme.default.utils.rowContent}
`;

const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;

    min-width: 0;
    height: 100%;

    flex: 1;
    z-index: 1;
    
     @media (max-width: 700px) {
          display: block;
         }

`;

const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
`;

const AlignEnd =  styled(_Section)`
    justify-content: flex-end;
    order: 1;  
`;

const Item = styled.div`
  position: relative; 
  height: 65px;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
   @media (max-width: 700px) {
          width: 100%;
         }
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               
               ${props.theme.default.utils.centerContent}
               
           }
          
          
        `}

     ${props=>props.hide && `
        
         @media (max-width: 700px) {
          display: none;
         }
        `}
`;

const Title = styled.div`
  margin: 0;
  font-weight: 500;
  text-align: left;
  font-size: 25px;
  color: #202124;

         @media (max-width: 700px) {
            font-size: 20px;
         }
`;

const Subtitle = styled.div`
  font-weight: 500;
  text-align: left;
  color: #5F6469;
  font-size: 16px;
  margin: 0 0.5rem;
`;

const Separator = styled.div`
  color: #757575;
  position: relative;
  border-left: 1px solid #757575;
  width: 2px;
  margin: 0 0.5rem;
  height: 2rem;
  opacity: 0.5;

         @media (max-width: 700px) {
          display: none;
         }
`;



const Item2 = styled.div`
  position: relative; 
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
  text-align: left;

  min-width: 45px; 
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               display: inline;
           }      
    `}

     ${props=>props.hide && `
         @media (max-width: 700px) {
          display: none;
         }
      `}


         @media (max-width: 700px) {
        margin-top: 1rem;
        width:100%;
         }
`;



const Title2 = styled.div`
width: 100%;

display: block;
font-size: 19px;
font-weight: 600;
color: #313033;
text-align: left;

`;

const Subtitle2 = styled.div`
width: 100%;

display: block;
font-size: 16px;
color: #5F6468;
font-weight: 300;

`;

const Content2 = styled.div`
  ${props=>props.theme.default.utils.rowContent}
  width: 95%;
  margin: 1rem auto;
  padding: 2rem 1rem;
     @media (max-width: 700px) {
          display: block;
         }

`;




export const UserCirlce = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: ${props => props.src? "url(" +props.src+ ")": "url('http://placehold.it/50x50')"};
  display: inline-block;
  margin: 0 0.1rem;
`;

export const IconCirlce = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  ${props=>props.theme.default.utils.centerContent}
  overflow: none; 
  font-size: 24px;
  background: #DFDFDF;
`;

export const PrivacyTab = styled.button`
 position: relative;
 border: none;
  border-radius: 22px;
  height: 40px;
  font-weight: 400;
  font-size:13px;
  color:#444444;
  background:white;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.theme.default.utils.rowContent}
  ${IconCirlce}{  
    height: 30px;
    width: 30px;
  }
 
`;

export const PrivacyTabContainer = styled.div`
  height: 75%;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.theme.default.utils.rowContent}
  width:100%;
`;

export const PrivacyText = styled.div`
    margin: 0 0.4rem;
`;
	