import React, { Component } from 'react';
import styled from 'styled-components';
import {  Link, withRouter } from 'react-router-dom';
import  firebase  from "firebase";
import Rebase from 're-base';
import Chart from "./Molecules/Chart"
const _ = require('lodash');

class Solicitudes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      solicitudes: [],
      profiles: [],
      dispersiones: [],
      cobros: [],
    }
      
    this.base = Rebase.createClass(firebase.database());
  }

  componentDidMount(){

    this.shutDownProfiles = this.base.syncState(`profile`, {
      context: this,
      asArray:true,
      state: "profiles",
      
      then(data){
        this.setState({loading: false})
      } 
    });
    this.shutDownSolicitudes = this.base.syncState(`solicitudes`, {
      context: this,
      asArray:true,
      queries: {
        orderByChild: 'status',
        equalTo: 1,
      },
      state: "solicitudes",
      then(data){
        this.setState({loading: false})
      }
      
    });



    this.shutDownDispersiones = this.base.syncState(`dispersiones`, {
      context: this,
      asArray:true,
      queries: {
        orderByChild: 'status',
        equalTo: 2,
      },
 


      state: "dispersiones",
      
      then(data){
        this.setState({loading: false})
         
      }
      
    });

    this.shutDownCobros = this.base.syncState(`cobros`, {
      context: this,
      asArray:true,
      queries: {
        orderByChild: 'status',
        equalTo: 2,
      },


      state: "cobros",
      
      then(data){
        this.setState({loading: false})
         
      }
      
    });

  }
   
  componetWillUnmount()
  {
    this.shutDownSolicitudes();
    this.shutDownProfiles();
    this.shutDownCobros();
    this.shutDownDispersiones();
  }
 

  render() {
    return(  <Body>
      <Title2>Datos generales</Title2>
      <Row>
       
        <Card>
          <CardContent id="dcontainer">
            <Row>
              <AlignStart>
                <Item>
                  <Title>
                    <Icon 
                      className="material-icons">people</Icon> Usuarios 
                  </Title>
                </Item>
                <Item>
                  <Separator/>
                </Item>
                <Item>
                  <Subtitle> {this.state.profiles.length} Usuarios</Subtitle>
                </Item>
              </AlignStart>
            </Row>
           
            <Chart
              key="po1"
              data={this.state.profiles}/>
             
          </CardContent>
          <CardFooter>
            <Link to="/usuarios">
              Ver todos
            </Link>
          </CardFooter>
        </Card>




        <Card>
          <CardContent id="dcontainer2">
            <Row>
              <AlignStart>
                <Item>
                  <Title>
                    <Icon 
                      className="material-icons">playlist_add</Icon> Solicitudes Recientes   
                  </Title>
                </Item>
                <Item>
                  <Separator/>
                </Item>
                <Item>
                  <Subtitle> {this.state.solicitudes.length} Solicitudes</Subtitle>
                </Item>
              </AlignStart>
            </Row>
            
            <Chart
              key="so1"
              data={this.state.solicitudes}/>
             
          </CardContent>
          <CardFooter>
            <Link to="/solicitudes">
              Ver todas
            </Link>
          </CardFooter>
        </Card>



        <Card>
          <CardContent>
            <Row>
              <AlignStart>
                <Item>
                  <Title>
                    <Icon 
                      className="material-icons">playlist_add_check</Icon> Dispersiones 
                  </Title>
                </Item>
                <Item>
                  <Separator/>
                </Item>
                <Item>
                  <Subtitle> {this.state.dispersiones.length} Solicitudes</Subtitle>
                </Item>
              </AlignStart>
            </Row>
            {_.map(_.take(this.state.dispersiones, 2), (item, key) => ( 
              <ItemCard>
                <MontoItemCard>${item.monto}</MontoItemCard>
                <NombreItemCard>{item.nombreUsuario}</NombreItemCard>
                <FechaItemCard>{ 
                  (()=>{
                    const d = new Date(item.fecha)
                    return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()} `
                  })()
                }</FechaItemCard>
              </ItemCard>

            ))}
            
             
          </CardContent>
          <CardFooter>
            <Link to="/dispersion/dispersados">
              Ver todos
            </Link>
          </CardFooter>
        </Card>


        <Card>
          <CardContent>
            <Row>
              <AlignStart>
                <Item>
                  <Title>
                    <Icon 
                      className="material-icons">monetization_on</Icon> Cobros 
                  </Title>
                </Item>
                <Item>
                  <Separator/>
                </Item>
                <Item>
                  <Subtitle> {this.state.cobros.length} Solicitudes</Subtitle>
                </Item>
              </AlignStart>
            </Row>
            {_.map(_.take(this.state.cobros, 2), (item, key) => ( 
              <ItemCard>
                <MontoItemCard>${item.monto}</MontoItemCard>
                <NombreItemCard>{item.nombreUsuario}</NombreItemCard>
                <FechaItemCard>{ 
                  (()=>{
                    const d = new Date(item.fecha)
                    return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()} `
                  })()
                }</FechaItemCard>
              </ItemCard>

            ))}
            
             
          </CardContent>
          <CardFooter>
            <Link to="/cobro/cobrados">
              Ver todos
            </Link>
          </CardFooter>
        </Card>

      </Row>
    </Body>
          
    )
  }
}




export default withRouter(Solicitudes)



//Code
const Body = styled.div`
  width: 90%;
  padding: 1rem;
  margin: 0 auto;
  overflow: none;
  //z-index: 0;
   @media (max-width: 700px)
          {
               width: 100%;
          }
`;

const Row = styled.div`
  margin: 0 auto;
  ${props=>props.theme.default.utils.rowContent}
`;


const Card = styled.div`
  width: 48%;
  height: 350px;
  background: white;
  border-radius: 7px;
  box-shadow: 0 0.5px 5px rgba(0,0,0,0.2);
  margin: 1.2rem 0;
  h1{font-weight: 500;}
  @media (max-width: 700px)
          {
               width: 100%;
          }
`;


const CardContent = styled.div`
  width: 100%;
  height: 300px;
  padding: 1rem;
`;

const CardFooter = styled.div`
width: 100%;
height: 50px;
border-top: solid 1px rgba(0,0,0,0.1);

${props=>props.theme.default.utils.centerContent}
color: blue;
font-size: 16px;


`;
//${props=>props.theme.default.utils.centerContent}

const ItemCard = styled.div`
width: 100%;
padding: 1rem;
border-radius: 7px;
margin: 0.5rem auto;
background: rgba(0,0,0,0.1);

position: relative;
`;


const MontoItemCard = styled.div`
font-size:26px;
font-weight: 500;


`;
const NombreItemCard = styled.div`
font-size:24px;
font-weight: 500;


`;
const FechaItemCard = styled.div`
position: absolute;
top: 1rem;
right: 1rem;
display: inline-block;
font-size: 12px;
color: gray;


`;


const Title = styled.div`

font-size: 20px;
color: rgba(0,0,0,0.4);
font-weight: 500;
display: inline-block;


`;

const Subtitle = styled.div`

font-size: 20px;
color: black;
font-weight: 500;
display: inline-block;

`;

const Icon = styled.i`
        vertical-align: bottom;
        margin-right: 0.5rem;
      `;

const _Section = styled.div`  
      display: inline-flex;
      align-items: flex-start;
      justify-content: center;
      min-width: 0;
      height: 100%;
      flex: 1;
      

  `;

const AlignStart = styled(_Section)`
      justify-content: flex-start;
      order: -1;
      margin-bottom: 2rem;
  `;

const AlignEnd = styled(_Section)`
      justify-content: flex-end;
      order: 1;
  `;

const Item = styled.div`
      position: relative; 
      height: 20px;
      min-width: 45px; 
      ${props=>props.theme.default.utils.centerContent}
      ${props=>props.cursor && "cursor: pointer;"}
      ${props=>props.margin && "margin: 0 0.5rem;"}
      ${props=>props.hideBig && `
          display: none;
          @media (max-width: 700px)
          {
              ${props.theme.default.utils.centerContent}
          }
      `}
      ${props=>props.hide && `
          @media (max-width: 700px)
          {
            display: none;
          }
      `}
      font-size: 18px;
      a{
          text-decoration: none;
      }
  `;
const Separator = styled.div`
    color: #757575;
    position: relative;
    border-left: 1px solid #757575;
    width: 2px;
    height: 1.2rem;
    opacity: 0.5;
  `;

const Title2 = styled.div`
  padding-top: 1.2rem;
color: rgba(0,0,0,0.5);
      font-size: 18px;
      font-weight: 500;
`;

