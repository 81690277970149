import React, { Component } from 'react';
import styled ,{keyframes, css, ThemeProvider}from 'styled-components';
import ReactChartkick, { LineChart, PieChart } from 'react-chartkick'
import Chart from 'chart.js'

ReactChartkick.addAdapter(Chart)

const _ = require('lodash');
 
const data = [
  {"name":"Workout", "data": {"2017-01-01": 3}},
  
];
class AppChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
        
      
  }
    
  freq(arr) {
    var a = [], b = [], prev;
    
    arr.sort();
    for ( var i = 0; i < arr.length; i++ ) {
      if ( arr[i] !== prev ) {
        a.push(arr[i]);
        b.push(1);
      } else {
        b[b.length-1]++;
      }
      prev = arr[i];
    }
    
    return [a, b];
  }

  

  componentDidUpdate(props, state){
      
    if(props !== this.props)
    { 
      var arr = _.map(this.props.data,(item)=> item.fecha)
      const data = this.freq(arr)
      const obj1 = {}

      _.map(data[0],(item, id)=> {

        const fecha = (()=>{
          const d = new Date(item)
          return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()} `
        })()

        obj1[fecha] = data[1][id]

      })
      this.setState({data:obj1})
    }

  }

  componentDidMount(props, state){
      
    if(this.props.data)
    { 

      var arr = _.map(this.props.data,(item)=> item.fecha)
      const data = this.freq(arr)
      const obj1 = {}

      _.map(data[0],(item, id)=> {

        const fecha = (()=>{
          const d = new Date(item)
          return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()} `
        })()

        obj1[fecha] = data[1][id]

      })
      this.setState({data:obj1})
    }

  }



  render() {
   
    return (

      <LineChart
        id="users-chart" 
        legend={false}
        min={0}
        max={10}
        height="220px"
        discrete={true}
        data={this.state.data}/>
    )
  }
}

export default AppChart;