import React  from 'react';
import styled from 'styled-components';
import Table from "./Table"

const Usuarios = () => (
  <Body>
    <Title>Usuarios</Title>
    <Table />
  </Body>
)

export default Usuarios


const Body = styled.div`
  width: 90%;
  padding: 1rem;
  margin: 0 auto;
  overflow: none;
`;

const Title = styled.div`
  padding-top: 1.2rem;
  color: rgba(0,0,0,0.5);
  font-size: 18px;
  font-weight: 500;
`;