import React from 'react';
import styled from 'styled-components';
import {  Route, Switch } from 'react-router-dom';
import Table from "./Table"

const Cobro = () => {
  return(
    <Body>
      <Switch>
        <Route
          exact
          path={"/cobro"} 
          render={() => {
            return (
              <React.Fragment>
                <Title>Adelantos por cobrar</Title>
                <Table
                  query={1}
                  key="2"/>
              </React.Fragment>
            )
          }}
        />
        <Route
          exact
          path={"/cobro/cobrados"} 
          render={() => {
            return (
              <React.Fragment>
                <Title>Adelantos cobrados</Title>
                <Table
                  query={2}
                  key="3"/>
              </React.Fragment>
            )
          }}
        />
      </Switch>
    </Body>
  )           
}

export default Cobro

const Body = styled.div`
  width: 90%;
  padding: 1rem;
  margin: 0 auto;
  overflow: none;
  @media print {
    width: 100%;
    padding:3px;
  }
`;
const Title = styled.div`
  padding-top: 1.2rem;
  color: rgba(0,0,0,0.5);
  font-size: 18px;
  font-weight: 500;
`;