import Rebase from "re-base";
import firebase from "firebase";
import {updateUsuario} from "../Usuarios"
import {updateDispersion,cancelarDispersion} from "../Dispersiones"

import {updateCobro, cancelarCobro} from "../Cobros"

const base = Rebase.createClass(firebase.database());
const uuid = require('uuid');


export async function crearConnectSolicitud(solicitud){
  //Actualizamos toda la informacion del usuario.
  //[Solicitud]

  firebase.database().ref(`connectSolicitudes/${solicitud.id}`).set(
    {
      id:solicitud.id,
      cobroId:"",
      dispersionesId:"",
      autorizacionId:"",
      analisisId:"",

    }).then((res)=>{

  }).catch((err)=>{
    alert("Hubo un error con la conexion, intenta de nuevo.")
    //Show Error mesage
  })
}

export async function updateConnectSolicitud(solicitud)
{
  firebase.database().ref(`connectSolicitudes/${solicitud.id}`)
    .update({...solicitud}).then((res)=>{
    }).catch((err)=>{
      alert("Hubo un error con la conexion, intenta de nuevo.")
    })
}

export async function createSolicitud(data){

  const id = uuid();

  const {
    fecha = Date.now(),
    usuario =firebase.auth().currentUser.uid,
    banco = "",
    clabe = "",
    convenio = "",
    cuenta = "",
 

    //This is just metadata
    files_adress = "",
    files_id = "",
    files_nomina = "",

    //This is just metadata
    monto = "0",
    montoAprobado = "0",
    status = 1,
  } = data 

 
  await updateUsuario({
    banco,
    clabe,
    convenio,
    cuenta,
    files_adress,
    files_id,
    files_nomina,
        
  })

  await crearConnectSolicitud({id})

     
  firebase.database().ref(`solicitudes/${id}`).set(
    {

      fecha,
      usuario,
      id,
      banco,
      clabe,
      convenio,
      cuenta,
      files_adress,
      files_id,
      files_nomina,
      monto,
      montoAprobado,
      status,
      action: "NEW_SOLICITUD"

    }).then((res)=>{

  }).catch((err)=>{
    alert("Hubo un error, intenta de nuevo.")
    //Show Error mesage
  })
}

export async function updateSolicitud(data, newConnect = null){
  //Actualizamos toda la informacion del usuario.
  //[Solicitud]
  firebase.database().ref(`solicitudes/${data.id}`).update(
    {...data,
      fechaDeActualizacion: Date.now(),
    }).then((res)=>{

  }).catch((err)=>{
    alert("Hubo un error, intenta de nuevo.")
    //Show Error mesage
  })

  //Si al actualizar hay nueva informacion de conextion entonces actualizamos el conector.
  if(newConnect)
    updateConnectSolicitud(newConnect)
}


export async function cancelarSolicitud(data){
  //Crea nueva propuesta
  //crearAnalisis({});
    
  if(data.status > 2)
    cancelarDispersion(data)
  else if(data.status >= 4)
    cancelarCobro(data)
  else
    updateSolicitud({id: data.id, status:0, action: "NEW_STATUS"});

  //crearAutorizacion(); -Este es un simple log de la autorizacion
}


export async function aceptarSolicitud(data){
  //[Solicitud,Usuario, analisis, montoAceptado]
  //Crea nueva propuesta
  //await crearAnalisis(data.analisis);
  //await crearDispersion({montoAprobado:"2400"}) (Esta dispersion es creada por el usuario)
  await updateSolicitud({
    id: data.id, 
    montoAprobado:data.monto, 
    fechaAprobada: data.fecha, status:2, action: "NEW_STATUS"})
  //await crearAutorizacion({}); -Este es un simple log de la autorizacion (ESTA DE MAS)
}