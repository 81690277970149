import React, { Component } from 'react';
import styled from 'styled-components';
import Loading from "Components/Molecules/Loading";
import firebase from "firebase"
import Rebase from "re-base"
import Close from "react-md-icon/dist/RoundClose";
import { dateFormatter } from 'utils/dateFormatter';

const _ = require('lodash');

class Bitacora extends Component 
{
  constructor(props) 
  {
    super(props);
    this.state={
      solicitud: {},
      loading: true,
      usuario: null,
      currentFile: null, 
      solicitudes:{} 
    }
    this.base = Rebase.createClass(firebase.database());
  }

  async componentDidMount(){
    this.base.fetch('bitacora/'+this.props.usuario,{})
      .then((user)=>{
        this.setState({usuario: user,  loading: false})
      })
    this.tranformArray()
  }

  tranformArray (){
    const solicitudes = {}
    if(this.props.solicitudes)
      for (var i = this.props.solicitudes.length - 1; i >= 0; i--) {
        solicitudes[this.props.solicitudes[i].id] = this.props.solicitudes[i]
      }
    this.setState({solicitudes})
  }

  render()
  {
    const close = this.props.close
    if(this.state.loading){
      return(
        <Loading  />
      )
    }
    
    return(
      <FullScreen>
        <PseudofullScreen onClick={close}/>
        <Card>
          <NavBar>
            <Content>
              <AlignStart>
                <Item2>
                  <Title2>
                    Bitácora de usuario 
                  </Title2>
                </Item2>
              </AlignStart>
              <AlignEnd>
              </AlignEnd>
            </Content>
            <Close
              onClick={close} 
              style= {{ 
                position: "absolute",
                "top": "1rem",
                "right": "1rem",
                "fontSize":"22px",
                "cursor":"pointer",
                zIndex: 9999
              }}/>

          </NavBar>
          <Container>

            <Title2>
              <b> Nombre:</b> {this.state.usuario.name}  {this.state.usuario.apellido}
            </Title2>

            <Title2>
              <b> Mail:</b> {this.state.usuario.mail}
            </Title2>

            <Title2>
              <b> Codigo Registro:</b> {this.state.usuario.codigoRegistro}
            </Title2>
            <Title2>
              <b> Fecha de registro:</b>{dateFormatter(this.state.usuario.fecha)}
   
            </Title2>
            <Title2>
              <b>Solicitudes:</b></Title2>
            <Ul>
              {_.map(this.state.usuario.solicitudes,(item)=>
                <li key={item.id}>
                  {this.state.solicitudes[item.id] && <p><b>Folio: </b>{this.state.solicitudes[item.id].folio} </p>}
                  {item.codigoAutorizo && <p><b>Código de Autorización:</b>{item.codigoAutorizo} </p>}
                  {item.fechaAutorizo && <p><b>Fecha de Autorización:</b>{dateFormatter(item.fechaAutorizo)}</p>}
                  {item.codigoTerminos && <p><b>Código de Términos:</b>{item.codigoTerminos} </p>}
                  {item.fechaTerminos && <p><b>Fecha de Términos:</b>
                    {dateFormatter(item.fechaTerminos)}
                  </p>}
                  {this.state.solicitudes[item.id] && <p><b>Nota: </b>{this.state.solicitudes[item.id].nota ? this.state.solicitudes[item.id].nota : "--"} </p>}
                </li>
              )}
            </Ul>                      
          </Container>
        </Card>
      </FullScreen>
    );
  }
}
export default Bitacora

export const UserCirlce = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: ${props => props.src? "url(" +props.src+ ")": "url('http://placehold.it/50x50')"};
  display: inline-block;
  margin: 0 0.1rem;
`;

export const IconCirlce = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  ${props=>props.theme.default.utils.centerContent}
  overflow: none; 
  font-size: 24px;
  background: #DFDFDF;
`;

export const PrivacyTab = styled.button`
 position: relative;
 border: none;
  border-radius: 22px;
  height: 40px;
  font-weight: 400;
  font-size:13px;
  color:#444444;
  background:white;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.theme.default.utils.rowContent}

${IconCirlce}{
  
  height: 30px;
  width: 30px;
}
`;

export const PrivacyTabContainer = styled.div`
  height: 75%;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.theme.default.utils.rowContent}
  width:100%;
`;

export const PrivacyText = styled.div`
    margin: 0 0.4rem;
`;


const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.4);
  padding: 0rem;
  padding-top: 1%;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
`;

const Card = styled.div`
  margin: 1rem auto;
  border-radius: 7px;
  overflow: hidden;
  width: 80%;
  padding-bottom: 1rem;
  color: rgba(0,0,0,0.8);
  background: white;
  position: relative;
  text-align: left;
  box-shadow: 0 0.5px 10px rgba(0,0,0,0.3);
  
    @media (max-width: 700px) {
    width: 90%;
    
  }
 
`;

const NavBar = styled.div`
 position: relative;
  width: 100%;
  top:0;
  left:0;
  width: 100%;
  height: 65px;
  background: white;
  ${props=>props.theme.default.utils.centerContent}
`;

const Container = styled.div`
  width:90%;
  margin: 2rem auto;
   padding-top: 65px;
   text-align: center;
`;


const Content = styled.div`
 position: relative;
 width: 100%;
 background: #F8F9FA;
    ${props=>props.theme.default.utils.rowContent}    
    padding: 1rem;
   @media (max-width: 700px) {
          display: inline-block;
         }
`;

const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;

    min-width: 0;
    height: 100%;

    flex: 1;
    z-index: 1;
    
     @media (max-width: 700px) {
          display: block;
         }

`;

const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
  
`;

const AlignEnd =  styled(_Section)`
    justify-content: flex-end;
    order: 1;
  
`;





const Title2 = styled.div`
  margin: 1rem 0;
  font-weight: 400;
  text-align: left;
  font-size: 22px;
  color: #202124;
`;


const Item2 = styled.div`
  position: relative; 
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
  text-align: left;

  min-width: 45px; 
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               display: inline;
           }      
    `}

     ${props=>props.hide && `
         @media (max-width: 700px) {
          display: none;
         }
      `}
`;


const Ul = styled.ul`
 text-align: left;
 li{border-bottom: 1px solid rgba(0,0,0,0.2);}
`;
