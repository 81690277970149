import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Landing from "Components/Molecules/Login"


export const AuthenticatedRouteRedirect = ({component: Component, authenticated, ...rest}) =>
{
  return (
    <Route
      {...rest}
      render={() => authenticated?
        <Component  {...rest} />
        :<Redirect to="/" />}
    />)
}

export const AuthenticatedRoute = ({component: Component, authenticated, ...rest}) =>
{
  
  return (
    <Route
      {...rest}
      render={() => authenticated?
        <Component   {...rest}  />
        :<Landing   {...rest} />}
    />)
}

export const AuthenticatedRouteHidden = ({component: Component, authenticated, ...rest}) =>
{
  return (
    <Route
      {...rest}
      render={() => authenticated?
        <Component  {...rest} />
        :null}
    />)
}



 

