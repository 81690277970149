import firebase from "firebase";
import {updateSolicitud} from "../Solicitudes"
import {updateDispersion} from "../Dispersiones"

export async function createCobro(data){
  const {
    id  = "",
    banco =  "",
    clabe = "",
    convenio = "",
    folio = "",
    cuenta = "",
    usuario = "",
    monto = 0,
    nombreUsuario = "",
    fechaAprobada = "",
   
  } =  data

  firebase.database().ref(`cobros/${id}`).set(
    {
      id,
      banco,
      clabe,
      convenio,
      cuenta,
      usuario,
      fechaAprobada,
      folio,
      fecha: Date.now(),
      nombreUsuario,
      solicitud: data.id,
      monto,
      status: 1,
      action: "NEW_COBRO"
    }).then((res)=>{

  }).catch((err)=>{
    alert("Hubo un error con la conexion, intenta de nuevo.")
  })
}


export async function updateCobro(data){
  firebase.database().ref(`cobros/${data.id}`).update(
    {...data,
      fechaDeActualizacion: Date.now(),
    }).then((res)=>{

  }).catch((err)=>{
    alert("Hubo un error, intenta de nuevo.")
   
  })
}

export async function cancelarCobro(data){
  updateCobro({id: data.id, status:0})
  updateSolicitud({id: data.id, status:0})
  updateDispersion({id: data.id, status:0})
}


export async function solicitudCobrada(data){
  updateCobro({id: data.id, status:2, action: "NEW_STATUS"})
  updateSolicitud({id: data.id, status:5, action: "NEW_STATUS"})
}