import React, { Component } from 'react';
import styled from 'styled-components';
import firebase from "firebase";
import Rebase from 're-base';
import UserModal from "Components/Molecules/UserModal";
import RoundSearch from "react-md-icon/dist/RoundSearch";
import placeHolderImage from "static/img/success.png"
	
const _ = require('lodash');

class UsersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      clickHeader: false,
      loading: true,
      profiles: [],
      usuario: null,
    }		
    this.base = Rebase.createClass(firebase.database());
  }

  componentDidMount(){
    this.shutDownProfiles = this.base.syncState(`profile`, {
      context: this,
      asArray:true,
      state: "profiles",
      then(data){
        this.setState({loading: false})
      }
    });
  }
   
  componetWillUnmount(){
    this.shutDownSolicitudes()
  }
 
  render() {

    const filteredUser = _.filter(this.state.profiles,(user)=>{
      if(this.state.filter)
        return user.name.toUpperCase()
          .includes(this.state.filter.toUpperCase())
			 ||
			user.apellido.toUpperCase()
			  .includes(this.state.filter.toUpperCase())
			||
			user.mail.toUpperCase()
			  .includes(this.state.filter.toUpperCase())
			||
			user.telefono.toUpperCase()
			  .includes(this.state.filter.toUpperCase())
				
      return true;})
    return(
      <Container >
        <PseudoContainer onClick={() => this.setState({clickHeader: false})}/>
        <Content>
          <Header active={this.state.clickHeader}>
            <Form onClick={() => this.setState({clickHeader: true})}>
              <IconContainer for="search">
                <SearchIcon> <i className="material-icons">search</i> </SearchIcon>
              </IconContainer>
              <Input 
                id="search" 
                type="text" 
                onChange={(e)=>{this.setState({filter:e.target.value})}}
                placeholder="Buscar usuario por Nombre, Apellido,Email, Telefono" 
                active={this.state.clickHeader}/>
							  
            </Form>
				
          </Header>

          <Information onClick={() => this.setState({clickHeader: false})}>
						
            <Item style={{width:"20%"}}>Nombre</Item>
            <Item style={{width:"20%"}}>Apellido</Item>
            <Item style={{width:"30%"}}>Mail</Item>
            <Item style={{width:"20%"}}>Telefono</Item>
          </Information>

          <MemberList onClick={() => this.setState({clickHeader: false})}>
            {this.state.loading && <Center> <b>Cargando...</b> </Center>}
            {!this.state.loading && this.state.profiles.length === 0 &&<Center> 
              <img
                src={placeHolderImage}
                alt="placeholder"/>
              <h2>No hay datos</h2>
            </Center>}
            {_.map(filteredUser,(usuario, index) => (		
              <Information 
                key={"user-order" + index}
                onClick={()=>{this.setState({usuario})}}
                style={{
								    background: "white",
								    fontSize: "22px",
								    height: "64px"
								 }}>

                <Item style={{width:"20%"}}>{usuario.name}</Item>
                <Item style={{width:"20%"}}>{usuario.apellido}</Item>
                <Item style={{width:"30%"}}>📧{usuario.mail}</Item>
                <Item style={{width:"20%"}}>{usuario.telefono}</Item>

              </Information>))} 
          </MemberList>

          <Footer onClick={() => this.setState({clickHeader: false})}>
				
          </Footer>
        </Content>
				


        {this.state.usuario && (
          <UserModal 
            close = {()=>{this.setState({usuario: null})}}
            usuario={this.state.usuario.id}/>
        )}
      </Container>
    )
  }
}


export default UsersTable

const Center = styled.div `
	width: 100%;
	min-height: 200px;
	${props=>props.theme.default.utils.centerContent}
	img{
		margin: 2rem auto;
		width: 170px;
	}
	color: rgba(0,0,0,0.4);

`
const Container = styled.div `
	width: 100%;
	height: auto;
	padding-top: 30px;
	display: flex;
	justify-content: center;
	position: relative;

`

const PseudoContainer = styled.div `
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: transparent;
	z-index: 9;
`;

const Content = styled.div `
	width: 100%;
	height: auto;
	border-radius: 7px;
	background: white;
	box-shadow: 0 0.5px 3px rgba(0, 0 ,0, 0.3);
	position: relative;
	z-index: 999;
	margin-bottom: 150px;
`;

const IconContainer = styled.label `
	width: 54px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	cursor: pointer;
`

const SearchIcon = styled(RoundSearch) `
	font-size: 25px;
	color: #878787;
`

const Input = styled.input `
	height: 100%;
	width: 55%;
	border: none;
	cursor: pointer;
	background: ${props => props.active ? "white": "#F5F5F5"};
	padding-left: 18px;
	position: relative;
	&:focus {
		outline: none;
		color: ${props => props.theme.default.color.blackCode};
	}
`
const Form = styled.div `
	width: 100%;
	height: 100%;
	display: flex;
	cursor: pointer;
`

const Header = styled.div `
	width: 100%;
	padding-right: 30px;
	height: 53px;
	background: ${props => props.active ? "white": "#F5F5F5"};
	border-bottom: 1px solid #D7D7D7;
	border-radius: 7px 7px 0 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Text = styled.div `
	font-family: Kollektif, 'sans-serif';
	font-size: 14px;
	color: ${props => props.inverse ? props.theme.default.blackCode : "#727272"};
`


const Information = styled.div `
	width: 100%;
	height: 40px;
	background: #F5F5F5;
	border-bottom: 1px solid #D7D7D7;
	display: flex;
	align-items: center;
	position: relative;

	 @media (max-width: 700px)
          {
            	//display: block;
            	//height: auto;

          }


`;
const Item = styled.div `
width: 15%;

font-size: 16px;	
height: 1.5rem;
margin-left: 1rem;



`


const MemberList = styled.div `
	width: 100%;
	height: auto;
	background: white;
`;

const Footer = styled.div `
	width: 100%;
	height: 42px;
	background: #F5F5F5;
	border-radius: 0 0 7px 7px;
	padding-left: 30px;
	display: flex;
	align-items: center;
	${Text}{
		font-family: Rubik, 'sans-serif';
		font-size: 14px;
		color: ${props => props.theme.default.color.blue};
		text-decoration: underline;
		cursor: pointer;
	}

`
